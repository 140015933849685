/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import { currencyFormatIndo } from "../../../helper/helper";
import axios from "../../../http/axios";
import { Pagination, Checkbox, Slider, Tag, Input, Spin } from "antd";
import "antd/dist/antd.min.css";
import "../../../antdstyle.css";
import {
  capitalizeTheFirstLetterOfEachWord,
  currencyFormatIndo,
} from "../../../helper/helper";
import NumberFormat from "react-number-format";

// import './index.css';
export default class ListProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      categoryList: [],
      ListCheck: [],
      ArrayCategory: [],
      pageNumber: 1,
      totalSize: 0,
      pageSize: 9,
      filterMinPrice: 0,
      filterMaxPrice: 50000000,
      filterMinPriceShow: 0,
      filterMaxPriceShow: 50000000,
      showLoading: false,
      showLoadingCategory: false,
      formSearchModel: false,
      filterProductMethod: false,
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    if (this.props.match.params.id) {
      // this.changeArrayCategory(this.props.match.params.id);
      this.getProductByCategory(
        this.state.pageNumber,
        this.state.pageSize,
        this.props.match.params.id
      );
    } else if (this.props.match.params.key) {
      this.setState({
        formSearchModel: true,
      });
      this.getProductByCategory(
        this.state.pageNumber,
        this.state.pageSize,
        null,
        this.props.match.params.key
      );
    } else {
      this.getProductAll(this.state.pageNumber, this.state.pageSize);
    }

    this.getCategoryList();
  };

  changeArrayCategory = (category) => {
    var list = this.state.ListCheck;
    list.push(category);
    this.setState({
      ListCheck: list,
      ArrayCategory: list,
    });
  };

  getCategoryList = async () => {
    this.setState({
      showLoadingCategory: true,
    });
    try {
      const res = await axios.get("categories");
      // console.log(res);
      if (res.status === 200) {
        var categoryList = res.data.result;

        if (this.props.match.params.id) {
          // console.log('cek');
          // this.changeArrayCategory(this.props.match.params.id);
          categoryList.map((x) => {
            var categoryValue = x.sub_kategori.filter(
              (item) => item.id === this.props.match.params.id
            );

            if (categoryValue.length) {
              var list = this.state.ListCheck;
              var ArrayCategoryList = this.state.ArrayCategory;
              list.push(this.props.match.params.id);
              ArrayCategoryList.push(categoryValue[0].categoryname);
              this.setState({
                ListCheck: list,
                ArrayCategory: ArrayCategoryList,
              });
            }
          });
        }
        this.setState({
          categoryList,
        });
      } else {
        this.setState({
          categoryList: [],
        });
      }
      this.setState({
        showLoadingCategory: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        categoryList: [],
        showLoadingCategory: false,
      });
    }
  };

  getProductAll = async (pageNumber, pageSize) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(
        `product-list/desc/updated_at/${pageSize}?page=${pageNumber}`
      );
      // console.log(res);
      if (res.status === 200) {
        var productList = res.data.result;

        this.setState({
          productList,
          totalSize: res.data.total_data,
        });
      } else {
        this.setState({
          productList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        productList: [],
        showLoading: false,
      });
    }
  };

  filterProduct = async () => {
    this.setState({
      showLoading: true,
      ArrayCategory: [],
    });
    try {
      const res = await axios.get(
        `product-list/desc/updated_at/${this.state.pageSize}?page=${this.state.pageNumber}`,
        {
          params: {
            category: this.state.ListCheck.toString(),
            range_price: `${String(this.state.filterMinPrice)}-${String(
              this.state.filterMaxPrice
            )}`,
          },
        }
      );
      // console.log(res);
      if (res.status === 200) {
        var productList = res.data.result;

        var ArrayCategoryList = [];
        this.state.categoryList.map((x) => {
          this.state.ListCheck.map((dt) => {
            var categoryValue = x.sub_kategori.filter((item) => item.id === dt);

            // console.log('categoryValue', categoryValue);

            if (categoryValue.length) {
              ArrayCategoryList.push(categoryValue[0].categoryname);
            }
          });
        });

        this.setState({
          productList,
          totalSize: res.data.total_data,
          ArrayCategory: ArrayCategoryList,
          filterProductMethod: true,
          filterMinPriceShow: this.state.filterMinPrice,
          filterMaxPriceShow: this.state.filterMaxPrice,
        });
      } else {
        this.setState({
          productList: [],
          filterProductMethod: true,
          filterMinPriceShow: this.state.filterMinPrice,
          filterMaxPriceShow: this.state.filterMaxPrice,
        });
      }
      this.setState({
        showLoading: false,
        filterMinPriceShow: this.state.filterMinPrice,
        filterMaxPriceShow: this.state.filterMaxPrice,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        productList: [],
        showLoading: false,
        filterProductMethod: false,
        filterMinPriceShow: this.state.filterMinPrice,
        filterMaxPriceShow: this.state.filterMaxPrice,
      });
    }
  };
  getProductByCategory = async (pageNumber, pageSize, category, key = null) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(
        `product-list/desc/updated_at/${pageSize}?page=${pageNumber}`,
        {
          params: {
            category: category,
            key: key,
          },
        }
      );
      // console.log(res);
      if (res.status === 200) {
        var productList = res.data.result;

        this.setState({
          productList,
          totalSize: res.data.total_data,
        });
      } else {
        this.setState({
          productList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        productList: [],
        showLoading: false,
      });
    }
  };

  onChangePageNumber = (pageNumber) => {
    // console.log(pageNumber);
    this.setState({
      pageNumber,
    });
    this.getProductAll(pageNumber, this.state.pageSize);
  };
  onShowSizeChange = (pageNumber, pageSize) => {
    this.setState({
      pageNumber,
      pageSize,
    });
    this.getProductAll(pageNumber, pageSize);
  };

  checklistCategory = (ListCheck) => {
    // console.log("checked = ", ListCheck);
    this.setState({
      ListCheck,
    });
  };

  formatter = (value) => {
    return currencyFormatIndo(value);
  };

  slideFilterPrice = (value) => {
    // console.log(value);
    this.setState({
      filterMinPrice: value[0],
      filterMaxPrice: value[1],
    });
  };

  render() {
    const { productList, categoryList } = this.state;
    // console.log(productList);
    return (
      <>
        <section className="shop spad">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 mb-4">
                <div className="shop__sidebar">
                  <div className="sidebar__categories">
                    <div className="section-title">
                      <h4>Categories</h4>
                    </div>
                    <div className="categories__accordion">
                      <div className="accordion" id="accordionExample">
                        <Checkbox.Group
                          style={{ width: "100%" }}
                          onChange={this.checklistCategory}
                          value={this.state.ListCheck}
                        >
                          {this.state.showLoadingCategory ? (
                            <div className="loader"></div>
                          ) : categoryList.length ? (
                            categoryList.map((category, index) => (
                              <div className="card" key={index}>
                                <div
                                  // className={`card-heading ${
                                  //   index === 0 ? "active" : ""
                                  // }`}
                                  className="card-heading active"
                                >
                                  <a
                                    data-toggle="collapse"
                                    data-target={`#collapse_${category.categoryname}`}
                                  >
                                    {capitalizeTheFirstLetterOfEachWord(
                                      category.categoryname
                                    )}
                                  </a>
                                </div>
                                <div
                                  id={`collapse_${category.categoryname}`}
                                  className="collapse show"
                                  // className={`collapse ${
                                  //   index === 0 ? "show" : ""
                                  // }`}
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body">
                                    {/* <ul> */}

                                    <div className="row">
                                      {category.sub_kategori.length
                                        ? category.sub_kategori.map(
                                            (sub, subIdx) => (
                                              // <li
                                              //   key={`${sub.categoryname}_${subIdx}`}
                                              // >
                                              //   <a
                                              //     href={`/page/shop-product/category=${sub.slugname}`}
                                              //   >
                                              //     {capitalizeTheFirstLetterOfEachWord(
                                              //       sub.categoryname
                                              //     )}
                                              //   </a>
                                              // </li>
                                              <div
                                                className="col-md-12"
                                                key={`${sub.categoryname}_${subIdx}`}
                                              >
                                                <Checkbox value={sub.id}>
                                                  {capitalizeTheFirstLetterOfEachWord(
                                                    sub.categoryname
                                                  )}
                                                </Checkbox>
                                              </div>
                                            )
                                          )
                                        : null}
                                      {/* </ul> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : null}
                        </Checkbox.Group>
                      </div>
                    </div>
                  </div>
                  <div className="sidebar__filter">
                    <div className="section-title">
                      <h4>Shop by price</h4>
                    </div>
                    <div className="filter-range-wrap">
                      {/* <div
                        className="price-range ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                        data-min="33"
                        data-max="99"
                      ></div> */}
                      <Slider
                        range={{ draggableTrack: true }}
                        onChange={this.slideFilterPrice}
                        value={[
                          this.state.filterMinPrice,
                          this.state.filterMaxPrice,
                        ]}
                        tipFormatter={this.formatter}
                        min={0}
                        max={50000000}
                      />
                      {/* <div className="price-input mb-0"> */}
                      <div style={{ width: "100%" }}>
                        <Input.Group compact>
                          {/* <InputNumber style={{ width: '45%', textAlign: 'center' }} placeholder="Minimum" value={currencyFormatIndo(this.state.filterMinPrice)}
                          onChange={(e) => this.setState({
                            filterMinPrice: e
                          })} /> */}
                          <NumberFormat
                            style={{
                              width: "50%",
                              textAlign: "center",
                            }}
                            className="site-input-right form-control"
                            value={this.state.filterMinPrice}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={""}
                            onValueChange={(values) =>
                              this.setState({
                                filterMinPrice: values.floatValue
                                  ? values.floatValue
                                  : 0,
                              })
                            }
                            allowEmptyFormatting={false}
                            allowNegative={false}
                          />
                          {/* <Input
                          className="site-input-split"
                          style={{
                            width: '10%',
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: 'none',
                          }}
                          placeholder="~"
                          disabled
                        /> */}
                          {/* <InputNumber
                          className="site-input-right"
                          style={{
                            width: '45%',
                            textAlign: 'center',
                          }}
                          placeholder="Maximum"
                          value={currencyFormatIndo(this.state.filterMaxPrice)}
                          onChange={(e) => this.setState({
                            filterMaxPrice: e
                          })}
                        /> */}
                          <NumberFormat
                            style={{
                              width: "50%",
                              textAlign: "center",
                            }}
                            className="site-input-right form-control"
                            value={this.state.filterMaxPrice}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={""}
                            onValueChange={(values) =>
                              this.setState({
                                filterMaxPrice: values.floatValue
                                  ? values.floatValue
                                  : 0,
                              })
                            }
                            allowEmptyFormatting={false}
                            allowNegative={false}
                          />
                        </Input.Group>
                      </div>
                      {/* <p>
                          Price: Rp.{" "}
                          <span>
                            {currencyFormatIndo(this.state.filterMinPrice)} -{" "}
                            {currencyFormatIndo(this.state.filterMaxPrice)}
                          </span>
                        </p>
                        <br /> */}
                      {/* <input type="text" value={this.state.filterMinPrice}  />
                          <input type="text" value={this.state.filterMaxPrice} /> */}
                    </div>
                  </div>
                  <div className="sidebar__filter mt-0">
                    <div className="filter-product">
                      <a
                        href="#"
                        style={{ width: "100%", color: "#ca1515" }}
                        className="text-center"
                        onClick={() => this.filterProduct()}
                      >
                        {this.state.showLoading ? "Loading" : "Filter"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                {this.state.ArrayCategory.length ? (
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <span style={{ marginRight: 8 }}>Categories:</span>
                      {this.state.ArrayCategory.map((tag, x) => (
                        <Tag color="red" key={x}>
                          {tag}
                        </Tag>
                      ))}
                    </div>
                  </div>
                ) : null}
                {this.state.formSearchModel ? (
                  <div className="row mb-4">
                    <div className="col-md-12">
                      <span style={{ marginRight: 8 }}>Key:</span>
                      <Tag color="red">{this.props.match.params.key}</Tag>
                    </div>
                  </div>
                ) : null}
                {this.state.filterProductMethod ? (
                  <div className="row mb-4">
                    <div className="col-md-12">
                      {/* {this.state.ArrayCategory.length ? (
                        <>
                          <span style={{ marginRight: 8 }}>Categories:</span>
                          {this.state.ArrayCategory.map((tag, x) => (
                            <Tag color="red" key={x}>
                              {tag}
                            </Tag>
                          ))}
                        </>
                      ) : null} */}
                      <span style={{ marginRight: 8 }}>Price:</span>
                      <Tag color="red">
                        {currencyFormatIndo(this.state.filterMinPriceShow)} -{" "}
                        {currencyFormatIndo(this.state.filterMaxPriceShow)}
                      </Tag>
                    </div>
                  </div>
                ) : null}
                <Spin spinning={this.state.showLoading}>
                  <div className="row">
                    {productList.length ? (
                      productList.map((item, idx) => (
                        <div className="col-lg-4 col-md-6" key={idx}>
                          <div className="product__item">
                            <div
                              className="product__item__pic set-bg"
                              style={
                                {
                                  // backgroundImage: `url(${
                                  //   item.sampul
                                  //     ? item.sampul
                                  //     : "https://static.hertz-audio.com/media/2021/05/no-product-image.png"
                                  // })`,
                                  // backgroundPosition: "center",
                                  // backgroundRepeat: "no-repeat",
                                  // backgroundSize: "270px 270px",
                                  // height: "270px",
                                }
                              }
                            >
                              {item.stock === "Habis" ? (
                                <div className="label sale">OUT OF STOCK</div>
                              ) : null}
                              <img
                                src={
                                  item.sampul
                                    ? item.sampul
                                    : "https://static.hertz-audio.com/media/2021/05/no-product-image.png"
                                }
                                style={{
                                  objectFit: "fill",
                                  width: "100%",
                                  height: "100%",
                                }}
                              />

                              <ul className="product__hover">
                                <li>
                                  <a
                                    href={
                                      item.sampul
                                        ? item.sampul
                                        : "https://static.hertz-audio.com/media/2021/05/no-product-image.png"
                                    }
                                    className="image-popup"
                                    target={"_blank"}
                                  >
                                    <span
                                      className="arrow_expand"
                                      title="Expand image"
                                    ></span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="product__item__text">
                              <h6>
                                <a
                                  href={`/page/product-detail/${item.catslug}/${item.slugname}`}
                                >
                                  {item.productname.length > 26
                                    ? item.productname.substr(0, 24) + "..."
                                    : item.productname}
                                </a>
                              </h6>
                              <div className="product__price">
                                {item.type_discount !== "0" ? (
                                  <>
                                    Rp. {item.last_price ? item.last_price : 0}{" "}
                                    <span>{item.price ? item.price : 0}</span>
                                  </>
                                ) : (
                                  <>Rp. {item.price ? item.price : 0}</>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-lg-12 col-md-12">
                        <div className="product__item">
                          <div
                            className="product__item__pic set-bg"
                            style={{
                              backgroundImage: `url(https://tradebharat.in/assets/catalogue/img/no-product-found.png)`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              // height: "270px",
                              // backgroundSize: "270px 270px",
                            }}
                          ></div>
                        </div>
                        {/* <p>SDADA ADASDAJKLSDDASJL</p> */}
                      </div>
                    )}

                    <div className="col-lg-12 col-md-12 mt-2">
                      <div className="text-center">
                        <Pagination
                          current={this.state.pageNumber}
                          onChange={this.onChangePageNumber}
                          total={this.state.totalSize}
                          showTotal={(total) => `Total ${total} items`}
                          pageSize={this.state.pageSize}
                          showSizeChanger
                          onShowSizeChange={this.onShowSizeChange}
                          pageSizeOptions={[9, 20, 50, 100]}
                          // responsive={true}
                        />
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
