/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Helmet from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import axios from "../../../http/axios";
import "../../../antdstyle.css";
// import { CheckCircleTwoTone } from "@ant-design/icons";
import "react-toastify/dist/ReactToastify.css";
// import { Spin } from "antd";
import { currencyFormatIndo, formatDateTimePlus } from "../../../helper/helper";
import { Redirect } from "react-router-dom";
import { Modal } from "antd";
export default class DetailOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDetail: [],
      notfound: false,
      no_whatsapp: "",
      isModalVisible: false,
    };
  }

  componentDidMount = () => {
    this.getDetailOrder();
    this.getContact();
  };

  getContact = async () => {
    try {
      const res = await axios.get(`content/contact`);
      // console.log(res);
      if (res.status === 200) {
        var label_name = res.data.result.filter(
          (item) => item.label_name === "Whatsapp"
        );
        if (label_name.length) {
          this.setState({
            no_whatsapp: label_name[0].contact_as,
          });
        }
      } else {
        this.setState({
          no_whatsapp: "",
        });
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        no_whatsapp: "",
      });
    }
  };
  getDetailOrder = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(`order-list/${this.props.match.params.id}`);
      // console.log(res);
      if (res.status === 200) {
        var listDetail = res.data.result;

        this.setState({
          listDetail,
          notfound: false,
        });
      } else {
        this.setState({
          listDetail: [],
          notfound: true,
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        listDetail: [],
        showLoading: false,
        notfound: true,
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  getSubtotal = (total, shipping) => {
    let pay = total.replaceAll(".", "");
    let ship = shipping.replaceAll(".", "");
    let xx = parseInt(pay) - parseInt(ship);
    return xx;
  };

  render() {
    const { listDetail } = this.state;
    return (
      <div>
        {!localStorage.getItem("token") ? (
          <Redirect to={"/auth/login"} />
        ) : (
          <>
            <Helmet>
              <title>Order Detail - Bikers Dream Administrator</title>
              <meta name="description" content="Detail order page" />
            </Helmet>

            <BreadCumb title="Order Detail" />

            {this.state.notfound ? (
              <section className="contact spad">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="text-center">
                        <h3>Data not found</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="contact spad">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="contact__content">
                        <div className="contact__address">
                          <h5>Order Detail</h5>
                          <ul>
                            <li>
                              <h6>Invoice</h6>
                              <p>{listDetail.invoice}</p>
                            </li>
                            <li>
                              <h6>Date</h6>
                              <p>{listDetail.created_at ? formatDateTimePlus(listDetail.created_at) : "-"}</p>
                            </li>
                            <li>
                              <h6>Status</h6>
                              <p>{listDetail.status}</p>
                            </li>
                            <li>
                              <h6>Payment Method</h6>
                              <p>{listDetail.name_bank} ({listDetail.number_account}) - {listDetail.an}</p>
                            </li>
                            <li>
                              <h6>Weight (gr)</h6>
                              <p>{listDetail.total_weigth}</p>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                              <h6>No Resi</h6>
                              <p>{listDetail.no_resi ? listDetail.no_resi : "-"}</p>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                              <h6>Shipping Method</h6>
                              <p>{listDetail.courier_code ? listDetail.courier_code : "-"} {listDetail.courier_service ? ` - ${listDetail.courier_service}` : ""}</p>
                            </li>
                            {listDetail.status_code === "7" ? (
                              <li style={{ marginBottom: "10px" }}>
                              <h6>Reason For Reject</h6>
                              <p>{listDetail.rationale ? listDetail.rationale : "-"}</p>
                            </li>
                            ) : null}
                            {listDetail.status_code === "6" ? (
                              <li>
                                <button
                                style={{marginTop: "30px"}}
                                  type="button"
                                  className="submit-btn"
                                  onClick={() =>
                                    this.toggleModal("isModalVisible")
                                  }
                                >
                                  Apply a retur
                                </button>
                              </li>
                            ) : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="contact__content">
                        <div className="contact__address">
                          <h5 style={{ margin: 0 }}>Items</h5>
                          <div className="table-responsive">
                            <table className="styled-table-variant">
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th className="text-right">Price</th>
                                  <th className="text-right">Quantity</th>
                                  <th className="text-right">Sub Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(listDetail) ? (
                                  <tr>
                                    <td colSpan={4} className="text-center">
                                      No Data
                                    </td>
                                  </tr>
                                ) : listDetail.data_product.length ? (
                                  listDetail.data_product.map((item, x) => (
                                    <tr key={x}>
                                      <td>
                                        <>
                                          <h6>{item.produkname}</h6>
                                          {/* <br /> */}
                                          <p
                                            style={{
                                              padding: 0,
                                              margin: 0,
                                              fontSize: "10px",
                                            }}
                                          >
                                            {item.options}
                                          </p>
                                          <p
                                            style={{
                                              padding: 0,
                                              margin: 0,
                                              fontSize: "10px",
                                            }}
                                          >
                                            {item.options_second}
                                          </p>
                                        </>
                                      </td>
                                      <td className="text-right">
                                        Rp. {item.price}
                                      </td>
                                      <td className="text-right">{item.qty}</td>
                                      <td className="text-right">
                                        Rp. {item.sub_total}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={4} className="text-center">
                                      No Data
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                              <tfoot>
                                {Array.isArray(listDetail) ? null : (
                                  <>
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td className="text-right">
                                        <h8>Sub Total</h8>
                                      </td>
                                      <td className="text-right">
                                        <h8>
                                          Rp.{" "}
                                          {currencyFormatIndo(
                                            this.getSubtotal(
                                              listDetail.total_payment,
                                              listDetail.total_shipping
                                            )
                                          )}
                                        </h8>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td className="text-right">
                                        <h8>Shipping Fee</h8>
                                      </td>
                                      <td className="text-right">
                                        <h8>Rp. {listDetail.total_shipping}</h8>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2}></td>
                                      <td className="text-right">
                                        <h8>Total</h8>
                                      </td>
                                      <td className="text-right">
                                        <h8>Rp. {listDetail.total_payment}</h8>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tfoot>
                            </table>
                          </div>
                          {/* <Spin spinning={this.state.showLoading}>
                      
                    </Spin> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            <Modal
              title={"Contact Info"}
              visible={this.state.isModalVisible}
              // onOk={false}
              onCancel={() => this.toggleModal("isModalVisible")}
              footer={null}
            >
              <div className="row">
                <div className="col-lg-12">
                  <a
                                  href={`https://wa.me/${this.state.no_whatsapp}`}
                                  className="whatsapp_float"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className={`fa fa-whatsapp mr-3`}></i>{" "}
                                  {this.state.no_whatsapp}
                                </a>
                  
                </div>
                <div className="col-lg-12 mt-4">
                  <i className="text-danger">
                    * Please contact the whatsapp number above to apply for a
                    return
                  </i>
                </div>
              </div>
            </Modal>
          </>
        )}
      </div>
    );
  }
}
