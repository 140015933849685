// import logo from './logo.svg';
// import { Router } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppLayout from "./pages/AppLayout";




function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <AppLayout />
      </BrowserRouter>
    </div>
  );
}

export default App;
