import React, { Component } from "react";
// import Kategori from "./Kategori";
// import Product from "./Product";
// import Banner from "./Banner";
// import Trend from "./Trend";
// import Service from "./Service";
import { Helmet } from "react-helmet";
import axios from "../../../http/axios";
import BreadCumb from "../../component/BreadCumb";
import parse from "html-react-parser";

export default class ContentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ContentObj: [],
      showLoading: false,
      content: "",
      title: "",
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    this.getContent();
  };

  getContent = async () => {
    this.setState({
      showLoading: true,
    });
    var slug = this.props.match.params.slug;
    try {
      const res = await axios.get("page/informations", {
        params: {
          slug_content: slug,
        },
      });
      // console.log(res);
      if (res.status === 200) {
        var ContentObj = res.data.result;

        this.setState({
          content: ContentObj.content,
          title: ContentObj.title,
        });
      } else {
        this.setState({
          content: "",
          title: "",
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        content: "",
        title: "",
        showLoading: false,
      });
    }
  };

  render() {
    const { content, title } = this.state;
    var titlePage = title
      ? `${title} - Bikers Dream Administrator`
      : `Content - Bikers Dream Administrator`;
    return (
      <>
        <div>
          <Helmet>
            <title>{titlePage}</title>
            <meta name="description" content="Content Page" />
          </Helmet>

          <BreadCumb title={title} />

          <section className="contact spad">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="contact__content">
                    <div className="contact__address">
                      <h5>{title}</h5>
                      <div> {parse(content)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
