import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from "redux";
import { Provider } from "react-redux";

const globalState = {
  totalCart: 0,
  listCart: []
};

// reducer
const rootReducer = (state = globalState, action) => {
  switch (action.type) {
    case "UPDATE_TOTAL_CART":
      return {
        ...state,
        totalCart: action.newValue,
      };
    case "ADD_TOTAL_CART":
      return {
        ...state,
        totalCart: parseInt(state.totalCart) + parseInt(action.newValue),
      };
    case "UPDATE_LIST_CART":
      return {
        ...state,
        listCart: action.newValue,
      };
    default:
      return state;
  }
};

const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
