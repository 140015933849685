/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { capitalizeTheFirstLetterOfEachWord } from "../../helper/helper";
import axios from "../../http/axios";
// import "../../antdstyle.css";
// import $ from 'jquery';

export default class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      showLoading: false,
      object: this.props.object
      // categoryList: this.props.categoryList,
      // showLoading: false,
      // object: this.props.object
    };
  }

  componentDidMount = () => {
    // console.log('navmenu', this.props.object);
    // this.getCategoryList();
  //   $(document).ready(function() {
  //     $(".header__menu").slicknav({
  //         prependTo: '#mobile-menu-wrap',
  //         allowParentLinks: true
  //       });
  // });
    // $(document)$(".header__menu").slicknav({
    //   prependTo: '#mobile-menu-wrap',
    //   allowParentLinks: true
    // });
  };

  componentWillMount() {
    // console.log('Component WILL MOUNT!')
    this.getCategoryList()
 }

  // componentDidUpdate = (prevProps) => {
  //       console.log('Component DID UPDATE MENU LUR!')
  //   //     this.getCategoryList()
  //     if (prevProps.categoryList.length !== this.state.categoryList.length) {
  //       this.setState({
  //         categoryList: this.props.categoryList,
  //         object: this.props.object,
  //       })
  //     }
  //    }

  renderNav = (categoryList, object) => {
    var navList = [
      <li className={object.location.pathname.includes(`/page/home`) ?"menu-item active" :"menu-item"} key={'home_1'}>
              {/* <Link to="/page/home">Home</Link> */}
              <a href={`/page/home`}>Home</a>
            </li>,
            <li className={object.location.pathname.includes(`/page/shop-product`) ?"menu-item active" :"menu-item"} key={'product_1'}>
            <a href={`/page/shop-product`}>Product</a>
            </li>
    ]
    // {categoryList.length
      categoryList.map((item, idx) => {
        if (item.sub_kategori.length) {
          var xx = (
            <li className={object.location.pathname.includes(`/page/products/${item.slugname}`) ?"menu-item-has-children active" :"menu-item-has-children"} key={`${item.slugname}_${idx}`}>
              <a href="#">
                {capitalizeTheFirstLetterOfEachWord(item.categoryname)} <i className="fa fa-angle-down"></i>
              </a>
              <div className="menu-subs menu-column-1"  style={{
                    // background: '#ca1515',
                    // boxShadow: 'black 0px 5px 15px',
                    // width: '300px'
                  }}>
              <ul>
                {item.sub_kategori.map((sub, subIdx) => (
                  <li key={`${sub.categoryname}_${subIdx}`}  style={{
                    // background: '#ca1515',
                    // boxShadow: 'black 0px 5px 15px',
                    borderBottom: '1px solid #C6C6C6'
                    // width: '300px'
                  }}>
                    <a href={`/page/products/${item.slugname}/category=${sub.id}`}>
                      {capitalizeTheFirstLetterOfEachWord(
                        sub.categoryname
                      )}
                    </a>
                  </li>
                ))}
              </ul>
              </div>
              
            </li>

          )
          navList.push(xx)
        } else {
          navList.push(
            <li className={object.location.pathname.includes(item.slugname) ?"menu-item active" :"menu-item"} key={`${item.slugname}_${idx}`}>
              <a href={`/page/products/only/category=${item.id}`}>
                {capitalizeTheFirstLetterOfEachWord(item.categoryname)} 
              </a>
            </li>
          )
        }
      })

      var contact = [
        <li className={object.location.pathname.includes(`/page/contact`) ?"menu-item active" :"menu-item"} key={'contact_1'}>
                {/* <Link to="/page/home">Home</Link> */}
                <a href={`/page/contact`}>Contact</a>
              </li>,
              <li className={object.location.pathname.includes(`/page/payment-confirmation`) ?"menu-item active" :"menu-item"} key={'payment-confirmation'}>
              <a href={`/page/payment-confirmation`}>Payment Confirmation</a>
              </li>
      ]

      var xx = navList.concat(contact)
      // console.log('xx', xx);
      return (xx);
          
  }

  getCategoryList = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get("categories");
      // console.log(res);
      if (res.status === 200) {
        var categoryList = res.data.result;

        this.setState({
          categoryList,
        });
      } else {
        this.setState({
          categoryList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        categoryList: [],
        showLoading: false,
      });
    }
  };

  render() {
    const { categoryList, object } = this.state;
    // const { object } = this.props;

    return (
      <>
        {/* <nav className="header__menu"> */}
          <ul className="menu-section-custom" style={{
            marginBottom: 0
          }}>
            {/* <li className={object.location.pathname.includes(`/page/home`) ?"active" :""}>
              <a href={`/page/home`}>Home</a>
            </li>
            <li className={object.location.pathname.includes(`/page/shop-product`) ?"active" :""}>
            <a href={`/page/shop-product`}>Product</a>
            </li> */}
            {
              categoryList.length ? (
                this.renderNav(categoryList, object)
              ) : null
            }

{/* {categoryList.length
      ? categoryList.map((item, idx) =>
          item.sub_kategori.length ? (
            // <li key={idx}>
            <li className={object.location.pathname.includes(`/page/products/${item.slugname}`) ?"active" :""} key={idx}>
              <a href="#">
                {capitalizeTheFirstLetterOfEachWord(item.categoryname)}
              </a>
              <ul className="dropdown">
                {item.sub_kategori.map((sub, subIdx) => (
                  <li key={`${sub.categoryname}_${subIdx}`}>
                    <a href={`/page/products/${item.slugname}/category=${sub.id}`}>
                      {capitalizeTheFirstLetterOfEachWord(
                        sub.categoryname
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li className={object.location.pathname.includes(item.slugname) ?"active" :""} key={idx} style={{display: 'block'}}>
              <a href={`/page/products/only/category=${item.id}`}>
                {capitalizeTheFirstLetterOfEachWord(item.categoryname)}
              </a>
            </li>
          )
        )
      : null} */}
          </ul>
        {/* </nav> */}
      </>
    );
  }
}
