import React, { Component } from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import ChangePassword from '../ChangePassword';
import ContactInfo from '../information/ContactInfo';
import ContentInfo from '../information/ContentInfo';
import DetailOrder from '../information/DetailOrder';
import DetailProfile from '../information/DetailProfile';
import OrderList from '../information/OrderList';
import PaymentConfirmation from '../information/PaymentConfirmation';
import Cart from '../product/Cart';
import Checkout from '../product/Checkout';
import ComponentProduct from '../product/ComponentProduct';
import DetailProduct from '../product/DetailProduct';
import Home from './Home';

export default class Content extends Component {
  render() {
    return (
      <>
          <Switch>
            {/* <Route path="/page/home" render={(props) => <Home {...props} />} /> */}
            <Route path="/page/home" render={(props) => <Home {...props} />} />
            <Route path="/page/detail-profile" render={(props) => <DetailProfile {...props} />} />
            <Route path="/page/content/:slug" render={(props) => <ContentInfo {...props} />} />
            <Route path="/page/shop-product" render={(props) => <ComponentProduct {...props} />} />
            <Route path="/page/product/search-by-key=:key" render={(props) => <ComponentProduct {...props} />} />
            <Route path="/page/products/:parent/category=:id" render={(props) => <ComponentProduct {...props} />} />
            <Route path="/page/product-detail/:category/:id" render={(props) => <DetailProduct {...props} />} />
            <Route path="/page/change-password" render={(props) => <ChangePassword {...props} />} />
            <Route path="/page/shopping-cart" render={(props) => <Cart {...props} />} />
            <Route path="/page/checkout" render={(props) => <Checkout {...props} />} />
            <Route path="/page/order-list" render={(props) => <OrderList {...props} />} />
            <Route path="/page/contact" render={(props) => <ContactInfo {...props} />} />
            <Route path="/page/payment-confirmation" render={(props) => <PaymentConfirmation {...props} />} />
            <Route path="/page/order/detail/:id" render={(props) => <DetailOrder {...props} />} />
            {/* <Route path="/page/home" component={Home}  />
            <Route path="/page/content/:slug" component={ContentInfo} />
            <Route path="/page/shop-product" component={ComponentProduct} />
            <Route path="/page/products/:parent/category=:id" component={ComponentProduct} />
            <Route path="/page/change-password" component={ChangePassword} /> */}
            <Redirect from="*" to="/page/home" />
          </Switch>
      </>
    )
  }
}
