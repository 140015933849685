import React, { Component } from "react";
import axios from "../../http/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showLoading: false,
      typepassword: 'password'
    };
  }

  // componentDidMount = () => {
  //   console.log(localStorage.getItem('URLpath'));
  // }

  handleLogin = async (e) => {
    e.preventDefault()
    this.setState({
      showLoading: true
    })
    
    
    try {
      const res = await axios.post('login', {
        username: this.state.email,
        userpass: this.state.password
      });
      // console.log(res);

      if (res.status === 200) {
        
        this.setState({
          showLoading: false
        })
        var result = res.data.result

        if (result.usertype === '2') {
          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT
          });
          
          localStorage.setItem('token', result.token)
          localStorage.setItem('firstlogin', true)
          delete result['token']
          localStorage.setItem('userInfo', JSON.stringify(result))
          
          if (localStorage.getItem('URLpath')) {
            var url = localStorage.getItem('URLpath')
            setTimeout(() => {
              window.location.href = url
            }, 1000);
          } else {
            setTimeout(() => {
              window.location.href = "/"
            }, 1000);
          }

          localStorage.removeItem('URLpath')
        } else {
          toast.error("Access Forbidden", {
            position: toast.POSITION.TOP_RIGHT
          });
        }

     
      }
    } catch (err) {
        // Handle Error Here
        this.setState({
          showLoading: false
        })
        var msg = err ? err.response.data.messages : 'Error invalid'
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT
        });
     
    }
      
   
  }

  render() {
    return (
      <>
       <Helmet>
          <title>Sign In - Bikers Dream Administrator</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <section className="checkout spad" style={{marginTop: '60px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h6 className="coupon__link">
                  <span className="icon_tag_alt"></span>
                  Don't have an account?{" "}
                  <a href="/auth/register">
                    {" "}
                    Click here to <b>REGISTER</b>
                  </a>
                </h6>
              </div>
            </div>
            <form onSubmit={this.handleLogin} className="checkout__form">
              <div className="row">
                <div className="col-lg-6">
                  <h5>LOGIN</h5>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        <p>
                          Email <span>*</span>
                        </p>
                        <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} required />
                      </div>
                      <div className="checkout__form__input">
                        <p>
                          Password <span>*</span>
                        </p>
                        <input type={this.state.typepassword} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} required />
                        {this.state.typepassword === "password" ? (
                                  // <button
                                  //   type="button"
                                  //   className="btn btn-outline-secondary"
                                  // >
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer", marginLeft: '-30px', opacity: '0.3' }}
                                      onClick={() =>
                                        this.setState({ typepassword: "text" })
                                      }
                                    ></i>
                                  
                                ) : (
                                 
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer", marginLeft: '-30px', opacity: '0.3' }}
                                      onClick={() =>
                                        this.setState({
                                          typepassword: "password",
                                        })
                                      }
                                    ></i>
                                  // </button>
                                )}
                        {/* <div className="input-group">
                              <input
                                type={this.state.typepassword}
                                // className="form-control"
                                value={this.state.password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                                required
                              />
                              <div className="input-group-append">
                                {this.state.typepassword === "password" ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                  >
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({ typepassword: "text" })
                                      }
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                  >
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({
                                          typepassword: "password",
                                        })
                                      }
                                    ></i>
                                  </button>
                                )}
                              </div>
                            </div> */}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        <p>
                          <a href="/auth/forgot-password">
                           Lupa password?
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        <button
                          type="submit"
                          style={{ width: "100%" }}
                          className="submit-btn"
                          disabled={this.state.showLoading}
                        >
                          {
                            this.state.showLoading ? (
                              <div>
                                <i className="fas fa-circle-notch fa-spin"></i> Loading
                              </div>
                            ) : (
                              "Login"
                            )
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6"></div> */}
              </div>
            </form>
          </div>
        </section>

        <ToastContainer theme='dark' />
      </>
    );
  }
}
