/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import SearchModel from "./SearchModel";
// import SocialMedia from "./SocialMedia";
import axios from "../../http/axios";
import { Spin } from "antd";
// import Select from "react-select";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialList: [],
      infoList: [],
      rekeningList: [],
      contactList: [],
      showLoadingContact: false,
      showLoadinginfoList: false,
      showLoadingrekeningList: false,
      showLoadingsocialList: false,
    };
  }

  componentDidMount = () => {
    this.getSocialList();
    this.getRekeningList();
    this.getInformation();
    this.getContact();
  };

  getContact = async () => {
    this.setState({
      showLoadingContact: true,
    });

    try {
      const res = await axios.get("page/contact");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result;
          var contactList = data.map((item) => {
            return {
              label_name: item.label_name,
              contact_as: item.contact_as,
              icon:
                item.label_name === "Whatsapp"
                  ? "fa fa-whatsapp"
                  : "fa fa-envelope",
              linkto:
                item.label_name === "Whatsapp"
                  ? `https://wa.me/${item.contact_as}`
                  : `mailto:${item.contact_as}`,
            };
          });
          this.setState({
            contactList,
          });
        }
      }
      this.setState({
        showLoadingContact: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        showLoadingContact: false,
        contactList: [],
      });
    }
  };

  getSocialList = async () => {
    try {
      const res = await axios.get("page/link-tree");
      // console.log(res);
      if (res.status === 200) {
        var socialList = res.data.result;

        this.setState({
          socialList,
        });
      } else {
        this.setState({
          socialList: [],
        });
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        socialList: [],
      });
    }
  };

  getRekeningList = async () => {
    try {
      const res = await axios.get("page/rekening-bank");
      // console.log(res);
      if (res.status === 200) {
        var rekeningList = res.data.result;

        this.setState({
          rekeningList,
        });
      } else {
        this.setState({
          rekeningList: [],
        });
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        rekeningList: [],
      });
    }
  };

  getInformation = async () => {
    try {
      const res = await axios.get("page/informations");
      // console.log(res);
      if (res.status === 200) {
        var infoList = res.data.result;

        this.setState({
          infoList,
        });
      } else {
        this.setState({
          infoList: [],
        });
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        infoList: [],
      });
    }
  };

  render() {
    const { socialList, rekeningList, infoList } = this.state;
    return (
      <>
        {/* <SocialMedia /> */}
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="footer__about">
                  <div className="footer__logo">
                    <a href="/">
                      <img src="/assets/img/logosidebar.png" alt="" />
                    </a>
                  </div>
                  <div className="footer__widget">
                    <Spin spinning={this.state.showLoadingContact}>
                      <ul>
                        {this.state.contactList.length
                          ? this.state.contactList.map((contact, z) => (
                              <li key={`widget_${z}`}>
                                <a
                                  href={contact.linkto}
                                  className="whatsapp_float"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className={`${contact.icon} mr-3`}></i>{" "}
                                  {/* <span style={{ fontSize: 10 }}> */}
                                  {contact.contact_as}
                                  {/* </span> */}
                                </a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </Spin>
                  </div>
                  {/* <div className="footer__payment">
                    <a href="#">
                      <img src="/assets/img/payment/payment-1.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-2.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-3.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-4.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-5.png" alt="" />
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6">
                <div className="footer__widget">
                  <h6>Information</h6>
                  <ul>
                    {infoList.length
                      ? infoList.map((item, x) => (
                          <li key={`info_${x}`}>
                            <a href={`/page/content/${item.slug}`}>
                              {x + 1}. {item.title}
                            </a>
                          </li>
                        ))
                      : null}
                    {/* <li>
                      <a href="#">About</a>
                    </li>
                    <li>
                      <a href="#">Blogs</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                    <li>
                      <a href="#">FAQ</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4">
                <div className="footer__widget">
                  <h6>Account</h6>
                  <ul>
                    <li>
                      <a href="/page/detail-profile">1. My Account</a>
                    </li>
                    {/* <li>
                      <a href="#">Orders Tracking</a>
                    </li> */}
                    <li>
                      <a href="/page/order-list">2. Order List</a>
                    </li>
                    {/* <li>
                      <a href="#">Wishlist</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-8 col-sm-8">
                <div className="footer__newslatter">
                  {/* <h6>NEWSLETTER</h6>
                  <form action="#">
                    <input type="text" placeholder="Email" />
                    <button type="submit" className="site-btn">
                      Subscribe
                    </button>
                  </form> */}
                  <div className="footer__social">
                    {rekeningList.length
                      ? rekeningList.map((item, idx) => (
                          <a
                            href={"#"}
                            title={item.name_bank}
                            key={`footer__social_${idx}`}
                          >
                            <img
                              src={item.link_icon}
                              alt=""
                              className="img-social"
                              style={{ borderRadius: "50%" }}
                            />
                          </a>
                        ))
                      : null}
                    {/* <a href="#">
                      <img src="/assets/img/payment/payment-1.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-2.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-3.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-4.png" alt="" />
                    </a>
                    <a href="#">
                      <img src="/assets/img/payment/payment-5.png" alt="" />
                    </a> */}
                  </div>
                  <div className="footer__social">
                    {socialList.length
                      ? socialList.map((social, y) => (
                          <a
                            href={social.url}
                            title={social.title}
                            key={`socilal_list_${y}`}
                          >
                            <img
                              src={social.link_icon}
                              alt=""
                              className="img-social"
                              style={{ borderRadius: "50%" }}
                            />
                          </a>
                        ))
                      : null}
                    {/* <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-youtube-play"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="footer__copyright__text">
                  <p>
                    Copyright &copy; {new Date().getFullYear()} All rights
                    reserved
                    {/* Copyright &copy; {new Date().getFullYear()} All rights
                    reserved | This template is made with{" "}
                    <i className="fa fa-heart" aria-hidden="true"></i> by{" "}
                    <a href="https://colorlib.com" target="_blank">
                      Colorlib
                    </a> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <SearchModel />
      </>
    );
  }
}
