import React from 'react'
import MainApp from "./MainApp";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AuthLayout from './auth/AuthLayout';

export default function AppLayout() {
  return (
      <BrowserRouter>
          <Switch>
            <Route path="/page" render={(props) => <MainApp {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="*" to="/page/home" />
          </Switch>
        </BrowserRouter>
  )
}
