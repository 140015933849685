import React, { Component } from "react";
import axios from "../../http/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : <Redirect to={"/auth/login"} />;
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpass: "",
      confpassword: "",
      newpass: "",
      isError: false,
      showLoading: false,
      success: false,
      successMessage: "",
      errorMessage: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    if (this.state.newpass !== this.state.confpassword) {
      this.setState({
        showLoading: false,
        isError: true,
        errorMessage:
          "Confirm new password must be same as new password, please try again",
      });
    } else {
      try {
        const res = await axios.post("change-password", {
          id: userInfo.id,
          oldpass: this.state.oldpass,
          newpass: this.state.newpass,
          confpassword: this.state.confpassword,
        });
        // console.log(res);

        if (res.status === 200) {
          this.setState({
            showLoading: false,
          });

          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/auth/login";
          }, 1000);
        }
      } catch (err) {
        // Handle Error Here
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  render() {
    return (
      <>
        {!localStorage.getItem("token") ? (
          <Redirect to={"/auth/login"} />
        ) : (
          <>
            <Helmet>
              <title>Change Password - Bikers Dream Administrator</title>
              <meta name="description" content="Change password page" />
            </Helmet>
            <section className="checkout spad">
              <div className="container">
                {/* <div className="row">
            <div className="col-lg-12">
              <h6 className="coupon__link">
                <span className="icon_tag_alt"></span>
                <a href="/auth/login">
                  {" "}
                  Click here to <b>Login</b>
                </a>
              </h6>
            </div>
          </div> */}
                <form onSubmit={this.handleSubmit} className="checkout__form">
                  <div className="row">
                    <div className="col-lg-6">
                      <h5>CHANGE PASSWORD</h5>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="checkout__form__input">
                            <p>
                              Old Password <span>*</span>
                            </p>
                            <input
                              type="password"
                              value={this.state.oldpass}
                              onChange={(e) =>
                                this.setState({ oldpass: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="checkout__form__input">
                            <p>
                              New Password <span>*</span>
                            </p>
                            <input
                              type="password"
                              value={this.state.newpass}
                              onChange={(e) =>
                                this.setState({ newpass: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="checkout__form__input">
                            <p>
                              Confirm New Password <span>*</span>
                            </p>
                            <input
                              type="password"
                              value={this.state.confpassword}
                              onChange={(e) =>
                                this.setState({ confpassword: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                        {this.state.isError ? (
                          <div className="col-lg-12">
                            <Alert variant={"danger"} show={this.state.isError}>
                              <strong>Error!</strong> {this.state.errorMessage}
                              <div className="float-right ml-100">
                                <span
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      isError: false,
                                    })
                                  }
                                >
                                  X
                                </span>
                              </div>
                            </Alert>
                          </div>
                        ) : null}

                        <div className="col-lg-12">
                          <div className="checkout__form__input">
                            {this.state.success ? (
                              <Alert variant={"success"}>
                                <strong>Success!</strong>{" "}
                                {this.state.successMessage}
                              </Alert>
                            ) : (
                              <button
                                type="submit"
                                style={{ width: "100%" }}
                                className="submit-btn"
                                disabled={this.state.showLoading}
                              >
                                {this.state.showLoading ? (
                                  <div>
                                    <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                    Loading
                                  </div>
                                ) : (
                                  "Change Password"
                                )}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6"></div> */}
                  </div>
                </form>
              </div>
            </section>
          </>
        )}
        <ToastContainer theme="dark" />
      </>
    );
  }
}
