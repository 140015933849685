/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Header from "./component/Header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./component/Footer";
import Content from "./content/homepage/Content";
// import { Link } from "react-router-dom";
import axios from "../http/axios";
import { Divider } from "antd";
export default class MainApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // totalCart: this.props.totalCart,
    //   categoryList: [
    //     {
    //         "id": "1",
    //         "id_parent": "0",
    //         "categoryname": "Shoes",
    //         "slugname": "shoes",
    //         "created_at": "2022-03-27 13:53:21",
    //         "updated_at": "2022-03-27 13:55:00",
    //         "sub_kategori": [
    //             {
    //                 "id": "2",
    //                 "id_parent": "1",
    //                 "categoryname": "Pantofel Shoes",
    //                 "slugname": "pantofel-shoes",
    //                 "created_at": "2022-03-27 13:55:26",
    //                 "updated_at": "2022-03-27 13:55:26"
    //             }
    //         ]
    //     },
    //     {
    //         "id": "3",
    //         "id_parent": "0",
    //         "categoryname": "Helm",
    //         "slugname": "helm",
    //         "created_at": "2022-03-27 13:56:14",
    //         "updated_at": "2022-03-27 13:56:14",
    //         "sub_kategori": [
    //             {
    //                 "id": "4",
    //                 "id_parent": "3",
    //                 "categoryname": "Helm Centro",
    //                 "slugname": "helm-centro",
    //                 "created_at": "2022-03-27 13:56:34",
    //                 "updated_at": "2022-03-27 13:56:34"
    //             },
    //             {
    //                 "id": "5",
    //                 "id_parent": "3",
    //                 "categoryname": "Helm Full Face",
    //                 "slugname": "helm-full-face",
    //                 "created_at": "2022-03-27 13:56:44",
    //                 "updated_at": "2022-03-27 13:56:44"
    //             }
    //         ]
    //     }
    // ]
    categoryList: []
    };
  }

//   componentWillMount() {
//     console.log('Component WILL MOUNT!')
//     this.getCategoryList()
//  }
// componentDidMount() {
//     console.log('Component DID MOUNT!')
//     this.getCategoryList()
//  }
// componentWillReceiveProps(newProps) {
//     console.log('Component WILL RECIEVE PROPS!')
//  }
// shouldComponentUpdate(newProps, newState) {
//     return true;
//  }
// componentWillUpdate(nextProps, nextState) {
//     console.log('Component WILL UPDATE!');
//     this.getCategoryList()
//  }
// componentDidUpdate(prevProps, prevState) {
//     console.log('Component DID UPDATE!')
//     this.getCategoryList()
//  }
  componentDidMount = () => {
    // console.log('mainapp', this.props);
    if (localStorage.getItem('firstlogin')) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      toast.info('Welcome, ' + userInfo.fullname + '!', {
        position: toast.POSITION.TOP_RIGHT
      });
      localStorage.removeItem('firstlogin')
    }
  }

  // // getInitialState = () => {
    
  // //   this.getCategoryList()
  // // }

  // componentWillMount = () => {
  //   // if(localStorage.getItem('navCategory')) {
  //   //   this.setState({
  //   //     categoryList: JSON.parse(localStorage.getItem('navCategory'))
  //   //   })
  //   // } else {
  //   //   this.getCategoryList()
  //   // }
  //   console.log('firstfunc');
  //   this.getCategoryList()
  // }

  getCategoryList = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get("categories");
      // console.log(res);
      if (res.status === 200) {
        var categoryList = res.data.result;
        localStorage.setItem('navCategory', JSON.stringify(categoryList))
        this.setState({
          categoryList,
        });
      } else {
        this.setState({
          categoryList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        categoryList: [],
        showLoading: false,
      });
    }
  };



  handleLogout = () => {
    localStorage.clear()
    window.location.href = '/auth/login'
  }

  render() {
    return (
      <div>
        {/* <div id="preloder">
          <div className="loader"></div>
        </div> */}

        

        <Header {...this.state} object={this.props} />
        <Content {...this.props}/>
        <Divider />
        <Footer />

        <ToastContainer theme='dark' />
      </div>
    );
  }
}