/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "../../../http/axios";

export default class Kategori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerList: [],
      allBanner: [],
      bannerHighlight: [],
      totalBanner: 0,
      showLoadingCategories: false,
      classCategories: "",
    };
  }

  componentDidMount = () => {
    this.getBanner();
    if (
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1
    ) {
      this.setState({
        classCategories: "",
      });
    } else {
      this.setState({
        classCategories: "categories__large__item",
      });
    }

    window.addEventListener("resize", (e) => {
      var isMobile =
        Math.min(window.screen.width, window.screen.height) < 768 ||
        navigator.userAgent.indexOf("Mobi") > -1;
      // console.log(window.screen.width);
      if (isMobile) {
        this.setState({
          classCategories: "",
        });
      } else {
        this.setState({
          classCategories: "categories__large__item",
        });
      }
    });
  };

  getBanner = async () => {
    this.setState({
      showLoadingCategories: true,
    });
    try {
      const res = await axios.get("page/banner");
      // console.log(res);
      if (res.status === 200) {
        var totalBanner = res.data.result.length;
        var allBanner = res.data.result;
        var bannerList = res.data.result.filter((dt) => dt.hg !== "1");

        var bannerHighlight = res.data.result.filter((dt) => dt.hg === "1");

        this.setState({
          bannerList,
          totalBanner,
          bannerHighlight,
          allBanner,
        });
      } else {
        this.setState({
          bannerList: [],
          bannerHighlight: [],
          allBanner: [],
          totalBanner: 0,
        });
      }
      this.setState({
        showLoadingCategories: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        bannerList: [],
        bannerHighlight: [],
        allBanner: [],
        showLoadingCategories: false,
        totalBanner: 0,
      });
    }
  };

  gotoLink = (dt) => {
    window.open(dt.url, "_blank");
  };

  render() {
    const { bannerList, bannerHighlight, totalBanner, allBanner } = this.state;
    return (
      <div>
        <section className="categories">
          {/* <section className="categories" style={{marginTop: '60px'}}> */}
          <div className="container-fluid">
            <div className="row">
              {totalBanner === 5 ? (
                <>
                  <div className="col-lg-6 p-0">
                    {bannerHighlight.length
                      ? bannerHighlight.map((dt, index) => (
                          // <div
                          //   className="categories__item categories__large__item set-bg"
                          //   style={{
                          //     backgroundImage: `url(${dt.link_icon})`,
                          //     cursor: "pointer",
                          //     objectFit: 'fill !important'
                          //   }}
                          //   onClick={() => this.gotoLink(dt)}
                          // >
                          //   <div className="categories__text"></div>
                          // </div>

                          <div
                            className={`categories__item ${this.state.classCategories} set-bg`}
                            style={{
                              // backgroundImage: `url(${item.link_icon})`,
                              cursor: "pointer",
                              padding: 0,
                              // backgroundPosition: 'center center',
                              // backgroundRepeat: 'no-repeat',
                              // backgroundSize: 'cover',
                              // objectFit: 'fill !important'
                              // height: '100%'
                            }}
                            onClick={() => this.gotoLink(dt)}
                          >
                            <img
                              src={dt.link_icon}
                              style={{
                                objectFit: "fill",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <div className="categories__text"></div>
                          </div>
                        ))
                      : null}
                  </div>
                  <div className="col-lg-6">
                    <div className="row">
                      {bannerList.length
                        ? bannerList.map((item, idx) => (
                            <div
                              className="col-lg-6 col-md-6 col-sm-6 p-0"
                              key={idx}
                            >
                              <div
                                className="categories__item set-bg"
                                style={{
                                  // backgroundImage: `url(${item.link_icon})`,
                                  cursor: "pointer",
                                  // objectFit: "fill !important",
                                  padding: 0,
                                }}
                                onClick={() => this.gotoLink(item)}
                              >
                                <img
                                  src={item.link_icon}
                                  style={{
                                    objectFit: "fill",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div className="categories__text">
                                  {/* <h4>Men’s fashion</h4>
                              <p>358 items</p>
                              <a href="#">Shop now</a> */}
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </>
              ) : null}

              {totalBanner === 4 ? (
                <>
                  <div className="col-lg-12">
                    <div className="row">
                      {allBanner.length
                        ? allBanner.map((item, idx) => (
                            <div
                              className="col-lg-3 col-md-3 col-sm-3 p-0"
                              key={idx}
                            >
                              <div
                                className={`categories__item ${this.state.classCategories} set-bg`}
                                style={{
                                  // backgroundImage: `url(${item.link_icon})`,
                                  cursor: "pointer",
                                  // backgroundPosition: 'center center',
                                  // backgroundRepeat: 'no-repeat',
                                  // backgroundSize: 'cover',
                                  // objectFit: 'fill !important'
                                  // height: '100%'
                                }}
                                onClick={() => this.gotoLink(item)}
                              >
                                <img
                                  src={item.link_icon}
                                  style={{
                                    objectFit: "fill",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div className="categories__text"></div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </>
              ) : null}
              {totalBanner === 3 ? (
                <>
                  <div className="col-lg-12">
                    <div className="row">
                      {allBanner.length
                        ? allBanner.map((item, idx) => (
                            <div
                              className="col-lg-4 col-md-4 col-sm-4 p-0"
                              key={idx}
                            >
                              <div
                                className={`categories__item ${this.state.classCategories} set-bg`}
                                style={{
                                  // backgroundImage: `url(${item.link_icon})`,
                                  cursor: "pointer",
                                  // backgroundPosition: "center center",
                                  // backgroundRepeat: "no-repeat",
                                  // backgroundSize: "cover",
                                  // objectFit: "fill !important",
                                  // height: '100%'
                                  padding: 0,
                                }}
                                onClick={() => this.gotoLink(item)}
                              >
                                <img
                                  src={item.link_icon}
                                  style={{
                                    objectFit: "fill",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div className="categories__text"></div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </>
              ) : null}
              {totalBanner === 2 ? (
                <>
                  <div className="col-lg-12">
                    <div className="row">
                      {allBanner.length
                        ? allBanner.map((item, idx) => (
                            <div
                              className="col-lg-6 col-md-6 col-sm-6 p-0"
                              key={idx}
                            >
                              <div
                                className={`categories__item ${this.state.classCategories} set-bg`}
                                style={{
                                  // backgroundImage: `url(${item.link_icon})`,
                                  cursor: "pointer",
                                  // backgroundPosition: "center center",
                                  // backgroundRepeat: "no-repeat",
                                  // backgroundSize: "cover",
                                  // objectFit: "fill !important",
                                  // height: '100%'
                                  padding: 0,
                                }}
                                onClick={() => this.gotoLink(item)}
                              >
                                <img
                                  src={item.link_icon}
                                  style={{
                                    objectFit: "fill",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div className="categories__text"></div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </>
              ) : null}
              {totalBanner === 1 ? (
                <>
                  <div className="col-lg-12">
                    <div className="row">
                      {allBanner.length
                        ? allBanner.map((item, idx) => (
                            <div
                              className="col-lg-12 col-md-12 col-sm-12 p-0"
                              key={idx}
                            >
                              <div
                                className={`categories__item ${this.state.classCategories} set-bg`}
                                style={{
                                  // backgroundImage: `url(${item.link_icon})`,
                                  cursor: "pointer",
                                  // backgroundPosition: "center center",
                                  // backgroundRepeat: "no-repeat",
                                  // backgroundSize: "cover",
                                  // objectFit: "fill !important",
                                  // height: '100%'
                                  padding: 0,
                                }}
                                onClick={() => this.gotoLink(item)}
                              >
                                <img
                                  src={item.link_icon}
                                  style={{
                                    objectFit: "fill",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                                <div className="categories__text"></div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
