/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Helmet from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import axios from "../../../http/axios";
import "../../../antdstyle.css";
// import { CheckCircleTwoTone } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spin } from "antd";
import { formatDateTimePlus } from "../../../helper/helper";
import { Redirect } from "react-router-dom";
import moment from "moment";

// const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOrder: [],
    };
  }

  componentDidMount = () => {
    this.getOrderlist();
  };

  getOrderlist = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(`order-list`);
      // console.log(res);
      if (res.status === 200) {
        var listOrder = res.data.result.sort(function (a, b) {
          return moment(b.updated_at).unix() - moment(a.updated_at).unix();
        });;
        // console.log(listOrder);

        this.setState({
          listOrder,
        });
      } else {
        this.setState({
          listOrder: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        listOrder: [],
        showLoading: false,
      });
    }
  };

  render() {
    return (
      <div>
        {!localStorage.getItem("token") ? (
          <Redirect to={"/auth/login"} />
        ) : (
          <>
            <Helmet>
              <title>Order List - Bikers Dream Administrator</title>
              <meta name="description" content="Order list page" />
            </Helmet>

            <BreadCumb title="Order List" />

            <section className="contact spad">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    {/* <div className="contact__content">
                    <div className="contact__address"> */}
                    <h5>Order List History</h5>
                    {/* <div className="shop__cart__table"> */}
                    <Spin spinning={this.state.showLoading}>
                      <div className="table-responsive">
                        <table className="styled-table-variant">
                          <thead>
                            <tr>
                              <th>Invoice</th>
                              <th>Date</th>
                              <th>Total Payment</th>
                              <th>Status</th>
                              <th>No Resi</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listOrder.length ? (
                              this.state.listOrder.map((item, x) => (
                                <tr key={x}>
                                  <td>{item.invoice}</td>
                                  <td>{formatDateTimePlus(item.created_at)}</td>
                                  <td>Rp. {item.total_payment}</td>
                                  <td>{item.status}</td>
                                  <td>{item.no_resi ? item.no_resi : ""}</td>
                                  {/* <td className="text-center">
                                  {item.default === "Y" ? (
                                    <CheckCircleTwoTone
                                      twoToneColor="#52c41a"
                                      style={{ fontSize: "24px" }}
                                    />
                                  ) : null}
                                </td> */}
                                  <td className="text-center">
                                    <a
                                      href={`/page/order/detail/${item.id}`}
                                      // onClick={() => this.gotoDetail(item)}
                                    >
                                      Detail
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10} className="text-center">
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Spin>
                    {/* </div> */}
                    {/* </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </section>

            <ToastContainer theme="dark" />
          </>
        )}
      </div>
    );
  }
}
