import axios from "axios";

const instance = axios.create({
  // timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    'Accept' : 'application/json, text/plain, */*',
    'Access-Control-Allow-Methods' : 'GET, PUT, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials' : true,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Expose-Headers':'*'
  },
});

// instance.defaults.baseURL = 'https://bikerapi.superteam.web.id/';
instance.defaults.baseURL = 'https://webservice.bikersdream.id/';

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


export default instance;