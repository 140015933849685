/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Footer from "../component/Footer";
import { Route, Switch } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import { Redirect } from "react-router-dom";
// import NavMenu from "../component/NavMenu";
import axios from "../../http/axios";
import { Divider } from "antd";
import Header from "../component/Header";

export default class AuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // totalCart: this.props.totalCart,
      categoryList: []
      // categoryList: this.props.categoryList
    };
  }
  componentDidMount = () => {
    // this.getCategoryList();
  }

  // componentDidUpdate(prevProps) {
  //   if(this.props.categoryList.length !== prevProps.categoryList.length) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
  //   {
  //     this.setState({
  //       categoryList: this.props.categoryList
  //     })
  //   }
  // } 

  // componentWillMount = () => {
  //   // if(localStorage.getItem('navCategory')) {
  //   //   this.setState({
  //   //     categoryList: JSON.parse(localStorage.getItem('navCategory'))
  //   //   })
  //   // } else {
  //   //   this.getCategoryList()
  //   // }
  //   this.getCategoryList()
  // }

  getCategoryList = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get("categories");
      // console.log(res);
      if (res.status === 200) {
        var categoryList = res.data.result;

        localStorage.setItem('navCategory', JSON.stringify(categoryList))
        this.setState({
          categoryList,
        });
      } else {
        this.setState({
          categoryList: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        categoryList: [],
        showLoading: false,
      });
    }
  };

  render() {
    return (
      <div>
        {localStorage.getItem("token") ? (
          <Redirect to={"/page/home"} />
        ) : (
          <div>
            <div id="preloder">
              <div className="loader"></div>
            </div>
            <Header {...this.state} object={this.props} />
            <Switch>
              <Route path={"/auth/login"} component={Login} />
              <Route path={"/auth/register"} component={Register} />
              <Route
                path={"/auth/forgot-password"}
                component={ForgotPassword}
              />
            </Switch>
            <Divider />
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
