import { Spin } from "antd";
import React, { Component } from "react";
// import Kategori from "./Kategori";
// import Product from "./Product";
// import Banner from "./Banner";
// import Trend from "./Trend";
// import Service from "./Service";
import { Helmet } from "react-helmet";
import axios from "../../../http/axios";
import BreadCumb from "../../component/BreadCumb";
// import parse from "html-react-parser";

export default class PaymentConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoadingContact: false,
      contactList: []
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    this.getContact();
  };

  getContact = async () => {
    this.setState({
      showLoadingContact: true,
    });

    try {
      const res = await axios.get("page/contact");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result;
          var contactList = data.filter(x => x.label_name === 'Whatsapp').map((item) => {
            return {
              label_name: item.label_name,
              contact_as: item.contact_as,
              icon:
                item.label_name === "Whatsapp"
                  ? "fa fa-whatsapp"
                  : "fa fa-envelope",
              linkto:
                item.label_name === "Whatsapp"
                  ? `https://wa.me/${item.contact_as}`
                  : `mailto:${item.contact_as}`,
            };
          });
          this.setState({
            contactList,
          });
        }
      }
      this.setState({
        showLoadingContact: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        showLoadingContact: false,
        contactList: [],
      });
    }
  };
  render() {
    return (
      <>
        <div>
          <Helmet>
            <title>Payment Confirmation - Bikers Dream Administrator</title>
            <meta name="description" content="Payment Confirmation Page" />
          </Helmet>

          <BreadCumb title={"Payment Confirmation"} />

          <section className="contact spad">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="contact__content">
                    <div className="contact__address">
                      <h5>Payment Confirmation</h5>
                      <h6>Have You Transferred?</h6>
                      <p>Please confirm payment to Bikers Dream customer service via Whatsapp below!</p>
                      <Spin spinning={this.state.showLoadingContact}>
                      <ul>
                        {this.state.contactList.length
                          ? this.state.contactList.map((contact) => (
                              <li>
                                <a
                                  href={contact.linkto}
                                  className="whatsapp_float"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className={`${contact.icon} mr-3`}></i>{" "}
                                  {contact.contact_as}
                                </a>
                              </li>
                            ))
                          : null}
                      </ul>
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
