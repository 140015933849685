/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from "antd";
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../http/axios";

export default class ComponentLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showLoading: false,
      typepassword: "password",
      isModalVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isModalVisible !== prevProps.isModalVisible) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.setdata();
    }
  }

  // componentDidMount = () => {
  //   this.setState({
  //     isModalVisible: false
  //   })
  // }

  setdata = () => {
    this.setState({
      isModalVisible: this.props.isModalVisible,
    });
  };

  handleLogin = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    try {
      const res = await axios.post("login", {
        username: this.state.email,
        userpass: this.state.password,
      });
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        var result = res.data.result;

        if (result.usertype === "2") {
          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT,
          });

          localStorage.setItem("token", result.token);
          localStorage.setItem("firstlogin", true);
          delete result["token"];
          localStorage.setItem("userInfo", JSON.stringify(result));

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error("Access Forbidden", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoading: false,
      });
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleCancle = () => {
    this.props.handleCancle();
  };

  getURLpath = () => {
    localStorage.setItem('URLpath', this.props.urlPath)
    window.location.href = "/auth/register"
  }

  render() {
    const { isModalVisible } = this.state;
    return (
      <div>
        <Modal
          title={null}
          visible={isModalVisible}
          // onOk={false}
          onCancel={() => this.handleCancle()}
          footer={null}
        >
          <form onSubmit={this.handleLogin} className="checkout__form">
            <div className="row">
              <div className="col-lg-12">
                <h5>LOGIN</h5>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="checkout__form__input">
                      <p>
                        Email <span>*</span>
                      </p>
                      <input
                        type="email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        required
                      />
                    </div>
                    <div className="checkout__form__input">
                      <p>
                        Password <span>*</span>
                      </p>
                      <input
                        type={this.state.typepassword}
                        value={this.state.password}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        required
                      />
                      {this.state.typepassword === "password" ? (
                        // <button
                        //   type="button"
                        //   className="btn btn-outline-secondary"
                        // >
                        <i
                          className="fa fa-eye"
                          style={{
                            cursor: "pointer",
                            marginLeft: "-30px",
                            opacity: "0.3",
                          }}
                          onClick={() =>
                            this.setState({ typepassword: "text" })
                          }
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash"
                          style={{
                            cursor: "pointer",
                            marginLeft: "-30px",
                            opacity: "0.3",
                          }}
                          onClick={() =>
                            this.setState({
                              typepassword: "password",
                            })
                          }
                        ></i>
                        // </button>
                      )}
                      {/* <div className="input-group">
                              <input
                                type={this.state.typepassword}
                                // className="form-control"
                                value={this.state.password}
                                onChange={(e) =>
                                  this.setState({ password: e.target.value })
                                }
                                required
                              />
                              <div className="input-group-append">
                                {this.state.typepassword === "password" ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                  >
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({ typepassword: "text" })
                                      }
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                  >
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({
                                          typepassword: "password",
                                        })
                                      }
                                    ></i>
                                  </button>
                                )}
                              </div>
                            </div> */}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="checkout__form__input">
                      <p>
                        <a href="/auth/forgot-password">Lupa password?</a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="checkout__form__input">
                      <button
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-btn"
                        disabled={this.state.showLoading}
                      >
                        {this.state.showLoading ? (
                          <div>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Loading
                          </div>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6"></div> */}
            </div>

            <div className="row mt-2">
              <div className="col-lg-12">
                <h6 className="coupon__link">
                  <span className="icon_tag_alt"></span>
                  Don't have an account?{" "}
                  <a href="#" onClick={() => this.getURLpath()}>
                    {" "}
                    Click here to <b>REGISTER</b>
                  </a>
                </h6>
              </div>
            </div>
          </form>
        </Modal>

        <ToastContainer theme="dark" />
      </div>
    );
  }
}
