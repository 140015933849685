import React, { Component } from "react";
import axios from "../../http/axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
export default class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showLoading: false,
      success: false,
      successMessage: "",
    };
  }

  handleForgot = async (e) => {
    e.preventDefault()
    this.setState({
      showLoading: true
    })
    
    
    try {
      const res = await axios.post('forgot-password', {
        email: this.state.email,
      });
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
          success: true,
          successMessage: res.data.messages
        })
        // var result = res.data.result
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (err) {
        // Handle Error Here
        this.setState({
          showLoading: false
        })
        var msg = err ? err.response.data.messages : 'Error invalid'
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT
        });
        
    }
      
   
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Forgot Password - Bikers Dream Administrator</title>
          <meta name="description" content="Forgot password page" />
        </Helmet>
        <section className="checkout spad" style={{marginTop: '60px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h6 className="coupon__link">
                  <span className="icon_tag_alt"></span>
                  <a href="/auth/login">
                    {" "}
                    Click here to <b>Login</b>
                  </a>
                </h6>
              </div>
            </div>
            <form onSubmit={this.handleForgot} className="checkout__form">
              <div className="row">
                <div className="col-lg-6">
                  <h5>FORGOT PASSWORD</h5>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        <p>
                          Email <span>*</span>
                        </p>
                        <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} required />
                      </div>
                    </div>
                   
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        {
                          this.state.success ? (
                            <Alert variant={'success'}>
                              <strong>Success!</strong> {this.state.successMessage}
                            </Alert>
                          ) : (
                            <button
                            type="submit"
                            style={{ width: "100%" }}
                            className="submit-btn"
                            disabled={this.state.showLoading}
                          >
                            {
                              this.state.showLoading ? (
                                <div>
                                  <i className="fas fa-circle-notch fa-spin"></i> Loading
                                </div>
                              ) : (
                                "Send reset link"
                              )
                            }
                          </button>
                          )
                          
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-6"></div> */}
              </div>
            </form>
          </div>
        </section>
        <ToastContainer theme='dark' />
      </>
    );
  }
}
