/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'

export default class BreadCumb extends Component {
  render() {
    const {title} = this.props
    return (
      <>
         <div className="breadcrumb-option" style={{marginTop: '70px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb__links">
                            <a href="/"><i className="fa fa-home"></i> Home</a>
                            {
                              this.props.ProductName ? (
                                <>
                                  <a href="#">{title}</a>
                                  <span>{this.props.ProductName}</span>
                                </>
                              ) : (
                                <span>{title}</span>
                              )
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  }
}
