/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Helmet from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import axios from "../../../http/axios";
import "../../../antdstyle.css";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Spin, Switch } from "antd";
import Select from "react-select";
import { Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";

const userInfo = localStorage.getItem("userInfo") ? (
  JSON.parse(localStorage.getItem("userInfo"))
) : (
  <Redirect to={"/auth/login"} />
);

export default class DetailProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: userInfo.fullname,
      email: userInfo.useremail,
      phonenumber: userInfo.contact,
      listAddress: [],
      isModalVisible: false,
      isDisabled: false,
      showLoadingProvince: false,
      showLoadingCity: false,
      provinceOption: [],
      cityOption: [],

      id_user: userInfo.id,
      id_address: '',
      id_kabupaten: "",
      id_province: "",
      address: "",
      receiver: "",
      phone: "",
      zip_code: "",
      default: "Y",

      isUpdateForm: false,
    };
  }

  componentDidMount = () => {
    this.getAddressUser();
    this.getProvince();
  };

  getProvince = async () => {
    this.setState({
      showLoadingProvince: true,
    });
    try {
      const res = await axios.get("ongkir/wilayah/province");
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var provinceOption = dt.map((item) => {
          return {
            value: item.province_id,
            label: item.province,
          };
        });

        this.setState({
          provinceOption,
        });
      } else {
        this.setState({
          provinceOption: [],
        });
      }
      this.setState({
        showLoadingProvince: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingProvince: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getCity = async (id) => {
    this.setState({
      showLoadingCity: true,
    });
    try {
      const res = await axios.get("ongkir/wilayah/city/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var cityOption = dt.map((item) => {
          return {
            value: item.city_id,
            label: item.city_name,
            province_id: item.province_id,
            province: item.province,
            type: item.type,
            postal_code: item.postal_code,
          };
        });

        this.setState({
          cityOption,
        });
      } else {
        this.setState({
          cityOption: [],
        });
      }
      this.setState({
        showLoadingCity: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingCity: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getAddressUser = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(`address-options/${userInfo.id}`);
      // console.log(res);
      if (res.status === 200) {
        // var listAddress = res.data.result;

        var listAddress = [];
        var dt = res.data.result.sort(function (a, b) {
          return b.id - a.id;
        });

        if (dt.length) {
          // console.log('dt', dt);
          listAddress = dt.map((item) => {
            return {
              id: item.id,
              id_user: item.id_user,
              address: item.address,
              receiver: item.receiver,
              zip_code: item.zip_code,
              default: item.default,
              phone: item.phone,
              city_id: item.raja_ongkir ? item.raja_ongkir.city_id : "",
              city_name: item.raja_ongkir ? item.raja_ongkir.city_name : "",
              province_id: item.raja_ongkir ? item.raja_ongkir.province_id : "",
              province: item.raja_ongkir ? item.raja_ongkir.province : "",
            };
          });
        }

        this.setState({
          listAddress,
        });
      } else {
        this.setState({
          listAddress: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        listAddress: [],
        showLoading: false,
      });
    }
  };

  changeDefault = async (record) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axios.get(
        "address-set-default/" + userInfo.id + "/" + record.id
      );
      // console.log(res);

      if (res.status === 200) {
        // this.setState({
        //   showLoading: false,
        // });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // this.toggleModal("showAddressDefaultModal");
        this.getAddressUser();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmitAddress = async (e) => {
    e.preventDefault();
    this.setState({
      isDisabled: true,
    });

    var dataInput = {
      id_user: this.state.id_user,
      address: this.state.address,
      id_province: this.state.id_province,
      id_kabupaten: this.state.id_kabupaten,
      receiver: this.state.receiver,
      default: this.state.default,
      zip_code: this.state.zip_code,
      phone: this.getNumberPhone(this.state.phone),
    };

    try {
      let res = {};
      if (this.state.isUpdateForm) {
        res = await axios.put(
          "address-options/" + this.state.id_address,
          dataInput
        );
      } else {
        res = await axios.post("address-options", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          isDisabled: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getAddressUser(this.state.id_user);

        this.toggleModal("isModalVisible");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        isDisabled: false,
      });
    }
  };

  handleChangeProvince = (e) => {
    if (e !== null) {
      this.setState({
        id_province: e.value,
      });
      this.getCity(e.value);
    } else {
      this.setState({
        id_province: "",
        id_kabupaten: "",
        cityOption: [],
      });
    }
  };
  handleChangeCity = (e) => {
    if (e !== null) {
      this.setState({
        id_kabupaten: e.value,
      });
    } else {
      this.setState({
        id_kabupaten: "",
      });
    }
  };

  getNumberPhone = (numb) => {
    
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd !== "0") {
      xd = `0${numb}`;
    }
    return xd;
  };
  getNumberPhoneReverse = (numb) => {
    
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd === "0") {
      xd = numb.substring(1);
    }
    return xd;
  };

  openModalAddress = () => {
    this.toggleModal("isModalVisible");
    this.setState({
      isUpdateForm: false,
      id_address: "",
      address: "",
      id_province: "",
      id_kabupaten: "",
      receiver: "",
      default: "Y",
      zip_code: "",
      phone: "",

      cityOption: [],
      isDisabled: false
    });
  };

  editDataAddress = (record) => {
    this.toggleModal('isModalVisible')
    this.getCity(record.province_id);
    this.setState({
      isUpdateForm: true,
      id_address: record.id,
      address: record.address,
      id_province: record.province_id,
      id_kabupaten: record.city_id,
      receiver: record.receiver,
      default: record.default,
      zip_code: record.zip_code,
      phone: this.getNumberPhoneReverse(record.phone),
      isDisabled: false
    });
  }

  render() {
    return (
      <div>
        {!localStorage.getItem("token") ? (
          <Redirect to={"/auth/login"} />
        ) : (
          <>
            <Helmet>
              <title>Detail Profile - Bikers Dream Administrator</title>
              <meta name="description" content="Detail profile page" />
            </Helmet>

            <BreadCumb title="Detail Profile" />

            <section className="contact spad">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="contact__content">
                      <div className="contact__address">
                        <h5>User Profile</h5>
                        <div className="row">
                          <div className="col-md-3">
                            <ul>
                              <li>
                                <h6>
                                  <i className="fa fa-user"></i> Name
                                </h6>
                                <p>{this.state.name}</p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-3">
                            <ul>
                              <li>
                                <h6>
                                  <i className="fa fa-phone"></i> Phone
                                </h6>
                                <p>
                                  <span>{this.state.phonenumber}</span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-3">
                            <ul>
                              <li>
                                <h6>
                                  <i className="fa fa-headphones"></i> Email
                                </h6>
                                <p>{this.state.email}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    {/* <div className="contact__content">
                    <div className="contact__address"> */}
                    <h5>
                      Address{" "}
                      <a
                        className="ml-4"
                        href="#"
                        onClick={() => this.openModalAddress()}
                      >
                        + Add New
                      </a>
                    </h5>
                    {/* <div className="shop__cart__table"> */}
                    <Spin spinning={this.state.showLoading}>
                      <div className="table-responsive">
                        <table className="styled-table-variant">
                          <thead>
                            <tr>
                              <th>Address</th>
                              <th>City</th>
                              <th>Province</th>
                              {/* <th>District</th>
                            <th>Village</th> */}
                              <th>Zip Code</th>
                              <th>Receiver</th>
                              <th>Contact</th>
                              <th>Default Address</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listAddress.length ? (
                              this.state.listAddress.map((item, x) => (
                                <tr key={x}>
                                  <td>
                                  <a
                                      href="#"
                                      onClick={() => this.editDataAddress(item)}
                                    >
                                       {item.address}
                                    </a>
                                  
                                    </td>
                                  <td>{item.city_name}</td>
                                  <td>{item.province}</td>
                                  {/* <td>{item.kecamatan}</td>
                                <td>{item.kelurahan}</td> */}
                                  <td>{item.zip_code}</td>
                                  <td>{item.receiver}</td>
                                  <td>{item.phone}</td>
                                  <td className="text-center">
                                    {item.default === "Y" ? (
                                      <CheckCircleTwoTone
                                        twoToneColor="#52c41a"
                                        style={{ fontSize: "24px" }}
                                      />
                                    ) : null}
                                  </td>
                                  <td className="text-center">
                                    <a
                                      href="#"
                                      onClick={() => this.changeDefault(item)}
                                    >
                                      Change to default
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={10} className="text-center">
                                  No Data
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Spin>
                    {/* </div> */}
                    {/* </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </section>

            <Modal
              title={null}
              visible={this.state.isModalVisible}
              // onOk={false}
              onCancel={() => this.toggleModal("isModalVisible")}
              footer={null}
              width={800}
              style={{ top: 20 }}
              
            >
              <form
                onSubmit={this.handleSubmitAddress}
                className="checkout__form"
              >
                <div className="row">
                  <div className="col-lg-12">
                    <h5>New Address</h5>
                    <div className="row">
                    <div className="col-md-12">
                      <div className="checkout__form__input">
                        <p>
                          Receiver <span>*</span>
                        </p>
                        <input
                          type="text"
                          value={this.state.receiver}
                          onChange={(e) =>
                            this.setState({ receiver: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                      <div className="col-md-12">
                        <div className="checkout__form__input">
                          <p>
                            Address <span>*</span>
                          </p>
                          <textarea
                            style={{
                              // height: '20px',
                              width: "100%",
                              border: "1px solid #e1e1e1",
                              borderRadius: "2px",
                              marginBottom: "25px",
                              fontSize: "14px",
                              paddingLeft: "20px",
                              paddingTop: "15px",
                              color: "#666666",
                            }}
                            value={this.state.address}
                            onChange={(e) =>
                              this.setState({ address: e.target.value })
                            }
                            required
                            rows={3}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          style={{
                            marginBottom: "25px",
                          }}
                        >
                          <p
                            style={{
                              color: "#444444",
                              fontWeight: 500,
                              marginBottom: "10px",
                            }}
                          >
                            Province <span className="text-danger">*</span>
                          </p>
                          <>
                            <Select
                              isClearable={true}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  height: 50,
                                  minHeight: 50,
                                }),
                              }}
                              onChange={(e) => this.handleChangeProvince(e)}
                              // onChange={(e) =>
                              //   this.setState({
                              //     id_province: e !== null ? e.value : "",
                              //   })
                              // }
                              options={this.state.provinceOption}
                              value={this.state.provinceOption.filter(
                                (obj) => obj.value === this.state.id_province
                              )}
                              isDisabled={this.state.showLoadingProvince}
                              isLoading={this.state.showLoadingProvince}
                              placeholder=""
                            />
                            <input
                              tabIndex={-1}
                              autoComplete="off"
                              style={{
                                opacity: 0,
                                height: 0,
                                position: "absolute",
                              }}
                              value={this.state.id_province}
                              required
                              onChange={(e) =>
                                this.setState({ id_province: e.target.value })
                              }
                            />
                          </>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          style={{
                            marginBottom: "25px",
                          }}
                        >
                          <p
                            style={{
                              color: "#444444",
                              fontWeight: 500,
                              marginBottom: "10px",
                            }}
                          >
                            City <span className="text-danger">*</span>
                          </p>
                          <>
                            <Select
                              isClearable={true}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  height: 50,
                                  minHeight: 50,
                                  width: '100%'
                                }),
                              }}
                              onChange={(e) => this.handleChangeCity(e)}
                              // onChange={(e) =>
                              //   this.setState({
                              //     id_kabupaten: e !== null ? e.value : "",
                              //   })
                              // }
                              options={this.state.cityOption}
                              value={this.state.cityOption.filter(
                                (obj) => obj.value === this.state.id_kabupaten
                              )}
                              isDisabled={this.state.showLoadingCity}
                              isLoading={this.state.showLoadingCity}
                              placeholder=""
                            />
                            <input
                              tabIndex={-1}
                              autoComplete="off"
                              style={{
                                opacity: 0,
                                height: 0,
                                position: "absolute",
                              }}
                              value={this.state.id_kabupaten}
                              required
                              onChange={(e) =>
                                this.setState({ id_kabupaten: e.target.value })
                              }
                            />
                          </>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="checkout__form__input">
                        <p>
                          Zip Code <span>*</span>
                        </p>
                        {/* <input
                          type="text"
                          value={this.state.zip_code}
                          onChange={(e) =>
                            this.setState({ zip_code: e.target.value })
                          }
                          required
                        /> */}
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.zip_code}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ zip_code: values.floatValue })
                          }
                          allowEmptyFormatting={false}
                          // format="#### #### #### ####"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="checkout__form__input">
                        <p>
                          Phone <span>*</span>
                        </p>
                        {/* <input
                          type="text"
                          value={this.state.contact}
                          onChange={(e) =>
                            this.setState({ contact: e.target.value })
                          }
                          required
                        /> */}
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.phone}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ phone: values.floatValue })
                          }
                          format="+62 (###) #### #### ####"
                          // mask={"_"}
                          allowEmptyFormatting={false}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="checkout__form__input">
                        <p>
                          Default <span>*</span>
                        </p>
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.default === "N" ? false : true}
                          onChange={(checked) =>
                            this.setState({
                              default: checked === true ? "Y" : "N",
                            })
                          }
                        />
                      </div>
                    </div>

                      <div className="col-md-12">
                        <div className="checkout__form__input">
                          <button
                            type="submit"
                            // style={{ width: "100%" }}
                            className="submit-btn"
                            disabled={this.state.isDisabled}
                          >
                            {this.state.isDisabled ? (
                              <div>
                                <i className="fa fa-refresh fa-spin"></i>{" "}
                                Executing your request
                              </div>
                            ) : (
                              "Save Changes"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Modal>

            <ToastContainer theme="dark" />
          </>
        )}
      </div>
    );
  }
}
