/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Carousel } from "antd";
import React, { Component } from "react";
import axios from "../../../http/axios";
import "../../../antdstyle.css";
// import parse from 'html-react-parser';

const contentStyle = {
  height: "200px",
  // color: "#fff",
  lineHeight: "30px",
  textAlign: "center",
  // background: '#364d79',
};

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      file_url: null,
    };
  }

  componentDidMount = () => {
    this.getSubCategoryRandom();
    this.getGeneralSetting();
  };

  getSubCategoryRandom = async () => {
    try {
      const res = await axios.get("categories/sub");
      // console.log(res);
      if (res.status === 200) {
        var categoryList = res.data.result;

        this.setState({
          categoryList,
        });
      } else {
        this.setState({
          categoryList: [],
        });
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        categoryList: [],
      });
    }
  };
  getGeneralSetting = async () => {
    try {
      const res = await axios.get("general-setting/banner");
      // console.log(res);
      if (res.status !== "") {
        var data = res.data.result;

        this.setState({
          file_url: data.file_url,
        });
      } else {
        this.setState({
          file_url: null,
        });
      }
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        file_url: null,
      });
    }
  };
  render() {
    return (
      <>
        <section
          // className="banner"
          className="banner set-bg"
          style={{
            height: "500px",
            backgroundImage: `url(${this.state.file_url})`,
            objectFit: "fill",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundColor: 'black',
            // opacity: '0.8'
          }}
        >
          <div
            style={{
              height: "500px",
              width: "100%",
              backgroundColor: "black",
              opacity: "0.8",
            }}
          >
            {/* <div> */}
            <div className="container">
              <div className="row">
                <div className="col-xl-7 col-lg-8 m-auto">
                  <Carousel autoplay style={{ marginTop: "150px" }}>
                    {this.state.categoryList.length
                      ? this.state.categoryList.map((item, x) => (
                          <div
                            className="banner__item"
                            style={{
                              paddingTop: "30px",
                              marginTop: "30px",
                            }}
                            key={`banner_${x}`}
                          >
                            <div className="banner__text" style={contentStyle}>
                              {/* <span
                                      style={{
                                        marginTop: "50px",
                                        paddingTop: "30px",
                                        fontSize: "20px",
                                      }}
                                    >
                                      <h3>{item.categoryname}</h3>
                                    </span> */}
                              <span>Categories</span>
                              {/* <span
                                      style={{
                                        marginTop: "50px",
                                        paddingTop: "30px",
                                        fontSize: "20px",
                                      }}
                                    >
                                      <h3>{item.categoryname}</h3>
                                    </span> */}
                              <h2
                                style={{
                                  color: "white",
                                  fontFamily: "Rufner",
                                }}
                              >
                                {item.categoryname}
                              </h2>
                              {/* <br /> */}
                              <a
                                href={`/page/products/${item.slugname}/category=${item.id}`}
                                style={{
                                  paddingTop: 0,
                                  textDecorationColor: "red",
                                  color: "white",
                                }}
                              >
                                Shop now
                              </a>
                              {/* <a href="#">Shop now</a> */}
                            </div>
                          </div>
                        ))
                      : null}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
