// import { Badge } from "reactstrap";

export const formatDate = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};
export const formatFormDates = (string) => {
  var d = new Date(string),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};
export const changeFormat = (string) => {
  var dt = string.split("/");
  var tgl = dt[1];
  var bulan = dt[0];
  var tahun = dt[2];
  return tgl + "/" + bulan + "/" + tahun;
};

export const formatDateSlash = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var date = new Date(str);
  let datenew =
    date.getDate() +
    "/" +
    parseInt(date.getMonth() + 1) +
    "/" +
    date.getFullYear();

  return datenew;
};

export const formatDateStrip = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
export const currencyFormatIndo = (num) => {
  const x = num.toString();
  const numb = x.split(".")[0];
  const commas = x.split(".")[1];
  const format = numb.toString().split("").reverse().join("");
  const convert = format.match(/\d{1,3}/g);
  const rp = convert.join(".").split("").reverse().join("");
  const rupiah = commas !== undefined ? rp + "," + commas : rp;
  if (num < 0) {
    return "-" + rupiah;
  }
  // console.log(rupiah);
  return rupiah;
};
export const removetwoZero = (num) => {
  const x = num.toString();
  const numb = x.split(".")[0];
  

  return numb;
};
export const handleNumber = (e) => {
  let numb = e.target.value;
  let regex = /^[0-9\b]+$/;
  // if value is not blank, then test the regex
  if (numb === "" || regex.test(numb)) {
    return numb;
  }
};
export const formatNpwp = (value) => {
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
    "$1.$2.$3.$4-$5.$6"
  );
};
export const formatNpwpToString = (value) => {
  var newStr = value.replaceAll(".", "");
  var txt = newStr.replaceAll("-", "");
  return txt;
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const formatDateIndo = (string) => {
  var bulans = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    // month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    // year = d.getFullYear().toString().substr(-2);
    year = d.getFullYear().toString();

  var xbulan = d.getMonth();
  var bulan = bulans[xbulan];

  // if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, bulan, year].join(" ");
};

export const formatDateDashboard = (string) => {
  var listbulan = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var listHari = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    day = "" + d.getDate(),
    hr = d.getDay();
  // year = d.getFullYear().toString();
  var hari = listHari[hr];
  var xbulan = d.getMonth();
  var bulan = listbulan[xbulan];

  // if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${hari}, ${day} ${bulan}`;
};
export const formatDateBirthdayDashboard = (string) => {
  var listbulan = [
    "January",
    "Febuary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // var listHari = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    day = "" + d.getDate();
  // hr = d.getDay();
  // year = d.getFullYear().toString();
  // var hari = listHari[hr];
  var xbulan = d.getMonth();
  var bulan = listbulan[xbulan];

  // if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${day} ${bulan}`;
};
export const formatDateDay = (string) => {
  var listbulan = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Ags",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var listHari = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    day = "" + d.getDate(),
    hr = d.getDay();
  // year = d.getFullYear().toString();
  var hari = listHari[hr];
  var xbulan = d.getMonth();
  var bulan = listbulan[xbulan];

  // if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${hari}, ${day} ${bulan}`;
};

export const formatDateTimeIndo = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str);
  var date = formatDateIndo(d);
  var hour = d.getHours();
  var minutes = d.getMinutes();
  hour = ("0" + hour).slice(-2);
  minutes = ("0" + minutes).slice(-2);
  // console.log(hour, minutes);
  var time = `${hour}:${minutes}`;

  return date + " " + time;
};

export const formatDateTimePlus = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  var hour = d.getHours();
  var minutes = d.getMinutes();
  var second = d.getSeconds();
  hour = ("0" + hour).slice(-2);
  minutes = ("0" + minutes).slice(-2);
  second = ("0" + second).slice(-2);

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  var tanggal = [day, month, year].join("/");
  var waktu = `${hour}:${minutes}:${second}`;

  return `${tanggal} ${waktu}`;
};

export const formatDateTime = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str);
  var date = formatFormDates(d);
  var hour = d.getHours();
  var minutes = d.getMinutes();
  hour = ("0" + hour).slice(-2);
  minutes = ("0" + minutes).slice(-2);
  // console.log(hour, minutes);
  var time = `${hour}:${minutes}`;

  return date + " " + time;
};
export const formatTimeIndo = (string) => {
  var str = string.replace(/\s/, 'T')+'Z';
  var d = new Date(str);
  var hour = d.getHours();
  var minutes = d.getMinutes();
  hour = ("0" + hour).slice(-2);
  minutes = ("0" + minutes).slice(-2);
  // console.log(hour, minutes);
  var time = `${hour}:${minutes}`;
  return time;
};

export const capitalizeTheFirstLetterOfEachWord = (words) => {
  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
};
export const getTwoWeeksRange = (date) => {
  // var dt = new Date(Date.now() + 12096e5);
  let now = new Date(date);
  now.setDate(now.getDate() + 14);
  return now;
};
export const getOneMonthRange = (date) => {
  // var dt = new Date(Date.now() + 12096e5);
  let now = new Date(date);
  now.setDate(now.getDate() + 31);
  return now;
};
export const getTwoWeeksRangeMinus = (date) => {
  // var dt = new Date(Date.now() + 12096e5);
  let now = new Date(date);
  now.setDate(now.getDate() - 14);
  return now;
};
export const getOneMonthRangeMinus = (date) => {
  // var dt = new Date(Date.now() + 12096e5);
  let now = new Date(date);
  now.setDate(now.getDate() - 31);
  return now;
};

// export const renderStatus = (status) => {
//   switch (String(status).toLowerCase()) {
//     case "success":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "completed":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "accept":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "hire new worker":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "employed":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "selection":
//       return (
//         <Badge color="info" pill>
//           {status}
//         </Badge>
//       );
//     case "received":
//       return (
//         <Badge color="warning" pill>
//           {status}
//         </Badge>
//       );
//     case "finished":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "confirmed":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "rejected":
//       return (
//         <Badge color="danger" pill>
//           {status}
//         </Badge>
//       );
//     case "reject":
//       return (
//         <Badge color="danger" pill>
//           {status}
//         </Badge>
//       );
//     case "failed":
//       return (
//         <Badge color="danger" pill>
//           {status}
//         </Badge>
//       );
//     case "closed":
//       return (
//         <Badge color="light" pill>
//           {status}
//         </Badge>
//       );
//     case "close":
//       return (
//         <Badge color="light" pill>
//           {status}
//         </Badge>
//       );
//     case "cancelled":
//       return (
//         <Badge color="danger" pill>
//           {status}
//         </Badge>
//       );
//     case "approved":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "yes":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "sent":
//       return (
//         <Badge color="success" pill>
//           {status}
//         </Badge>
//       );
//     case "no":
//       return (
//         <Badge color="danger" pill>
//           {status}
//         </Badge>
//       );
//     case "true":
//       return (
//         <span className="badge bg-inverse-success">
//           {status === "True" ? "Active" : "No Active"}
//         </span>
//       );
//     case "not sent":
//       return (
//         <Badge color="danger" pill>
//           {status}
//         </Badge>
//       );
//     case "y":
//       return (
//         <Badge color="success" pill>
//           {status === "Y" ? "Yes" : "No"}
//         </Badge>
//       );
//     case "n":
//       return (
//         <Badge color="danger" pill>
//           {status === "Y" ? "Yes" : "No"}
//         </Badge>
//       );
//     default:
//       return (
//         <Badge color="warning" pill>
//           {status}
//         </Badge>
//       );
//   }
// };
