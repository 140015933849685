import React, { Component } from "react";
import axios from "../../http/axios";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceOption: [],
      cityOption: [],
      districtOption: [],
      urbanVillageOption: [],
      genderOption: [
        {
          value: 1,
          label: "Male",
        },
        {
          value: 2,
          label: "Female",
        },
      ],
      id_province: "",
      id_kabupaten: "",
      district: "",
      fullname: "",
      gender: "",
      email: "",
      userpass: "",
      confpassword: "",
      contact: "",
      address: "",
      id_kelurahan: "",
      zip_code: "",
      agreePolicy: false,
      showLoading: false,
      showLoadingProvince: false,
      showLoadingCity: false,
      showLoadingDistrict: false,
      showLoadingUrbanVillage: false,
      successMessage: "",
      isError: false,
      success: false,
      errorMessage: "",
      typeNewpassword: 'password',
      typeConfpassword: 'password',
    };
  }

  componentDidMount = () => {
    this.getProvince();
  };

  getProvince = async () => {
    this.setState({
      showLoadingProvince: true,
    });
    try {
      const res = await axios.get("ongkir/wilayah/province");
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var provinceOption = dt.map((item) => {
          return {
            value: item.province_id,
            label: item.province,
          };
        });

        this.setState({
          provinceOption,
        });
      } else {
        this.setState({
          provinceOption: [],
        });
      }
      this.setState({
        showLoadingProvince: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingProvince: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getCity = async (id) => {
    this.setState({
      showLoadingCity: true,
    });
    try {
      const res = await axios.get("ongkir/wilayah/city/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var cityOption = dt.map((item) => {
          return {
            value: item.city_id,
            label: item.city_name,
            province_id: item.province_id,
            province: item.province,
            type: item.type,
            postal_code: item.postal_code,
          };
        });

        this.setState({
          cityOption,
        });
      } else {
        this.setState({
          cityOption: [],
        });
      }
      this.setState({
        showLoadingCity: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingCity: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  // getProvince = async () => {
  //   this.setState({
  //     showLoadingProvince: true,
  //   });
  //   try {
  //     const res = await axios.get("propinsi");
  //     // console.log(res);
  //     if (res.status === 200) {
  //       var dt = res.data.result;
  //       var provinceOption = dt.map((item) => {
  //         return {
  //           value: item.id,
  //           label: item.name,
  //         };
  //       });

  //       this.setState({
  //         provinceOption,
  //       });
  //     } else {
  //       this.setState({
  //         provinceOption: [],
  //       });
  //     }
  //     this.setState({
  //       showLoadingProvince: false,
  //     });
  //   } catch (err) {
  //     // Handle Error Here
  //     this.setState({
  //       showLoadingProvince: false,
  //     });
  //     var msg = err ? err.message : "Error invalid";
  //     toast.error(msg, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  
  // getCity = async (id) => {
  //   this.setState({
  //     showLoadingCity: true,
  //   });
  //   try {
  //     const res = await axios.get("kabupaten/" + id);
  //     // console.log(res);
  //     if (res.status === 200) {
  //       var dt = res.data.result;
  //       var cityOption = dt.map((item) => {
  //         return {
  //           value: item.id,
  //           label: item.name,
  //         };
  //       });

  //       this.setState({
  //         cityOption,
  //       });
  //     } else {
  //       this.setState({
  //         cityOption: [],
  //       });
  //     }
  //     this.setState({
  //       showLoadingCity: false,
  //     });
  //   } catch (err) {
  //     // Handle Error Here
  //     this.setState({
  //       showLoadingCity: false,
  //     });
  //     var msg = err ? err.message : "Error invalid";
  //     toast.error(msg, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };
  getDistrict = async (id) => {
    this.setState({
      showLoadingDistrict: true,
    });
    try {
      const res = await axios.get("kecamatan/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.result;
        var districtOption = dt.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        this.setState({
          districtOption,
        });
      } else {
        this.setState({
          districtOption: [],
        });
      }
      this.setState({
        showLoadingDistrict: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingDistrict: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  getUrbanVillage = async (id) => {
    this.setState({
      showLoadingUrbanVillage: true,
    });
    try {
      const res = await axios.get("kelurahan/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.result;
        var urbanVillageOption = dt.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        this.setState({
          urbanVillageOption,
        });
      } else {
        this.setState({
          urbanVillageOption: [],
        });
      }
      this.setState({
        showLoadingUrbanVillage: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingUrbanVillage: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleChangeProvince = (e) => {
    if (e !== null) {
      this.setState({
        id_province: e.value,
      });
      this.getCity(e.value);
    } else {
      this.setState({
        id_province: "",
        id_kabupaten: "",
        cityOption: [],
      });
    }
  };
  handleChangeCity = (e) => {
    if (e !== null) {
      this.setState({
        id_kabupaten: e.value,
      });
      // this.getDistrict(e.value);
    } else {
      this.setState({
        id_kabupaten: "",
        // district: "",
        // id_kelurahan: "",
        // districtOption: [],
        // urbanVillageOption: [],
      });
    }
  };
  handleChangeDistrict = (e) => {
    if (e !== null) {
      this.setState({
        district: e.value,
      });
      this.getUrbanVillage(e.value);
    } else {
      this.setState({
        district: "",
        id_kelurahan: "",
        urbanVillageOption: [],
      });
    }
  };
  handleChangeUrbanVillage = (e) => {
    if (e !== null) {
      this.setState({
        id_kelurahan: e.value,
      });
    } else {
      this.setState({
        id_kelurahan: "",
      });
    }
  };

  getNumberPhone = (numb) => {
    // var xd = ""
    // if (numb) {
    //   xd = `0${numb}`
    // }

    // return xd
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd !== "0") {
      xd = `0${numb}`;
    }
    return xd;
  };

  handleRegister = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = {
      fullname: this.state.fullname,
      gender: String(this.state.gender),
      email: this.state.email,
      userpass: this.state.userpass,
      confpassword: this.state.confpassword,
      contact: this.getNumberPhone(this.state.contact),
      address: this.state.address,
      id_kabupaten: this.state.id_kabupaten,
      id_province: this.state.id_province,
      zip_code: String(this.state.zip_code),
    };

    if (this.state.confpassword !== this.state.userpass) {
      // toast.error('Account password must be same as confirm password, please try again', {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      this.setState({
        showLoading: false,
        isError: true,
        errorMessage:
          "Confirm password must be same as account password, please try again",
      });
    } else {
      try {
        const res = await axios.post("register", dataInput);
        if (res.status === 200) {
          this.setState({
            success: true,
            successMessage: res.data.messages,
          });
          // var result = res.data.result
          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        this.setState({
          showLoading: false,
        });
        setTimeout(() => {
          window.location.href = '/auth/login'
        }, 1000);

        

        // if (localStorage.getItem('URLpath')) {
        //   window.location.href = localStorage.getItem('URLpath')

        //   localStorage.removeItem('URLpath')
        // }
      } catch (err) {
        // Handle Error Here
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Register - Bikers Dream Administrator</title>
          <meta name="description" content="Register page" />
        </Helmet>
        <section className="checkout spad" style={{marginTop: '60px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h6 className="coupon__link">
                  <span className="icon_tag_alt"></span>
                  Already have an account?{" "}
                  <a href="/auth/login">
                    {" "}
                    Click here to <b>Login</b>
                  </a>
                </h6>
              </div>
            </div>
            <form onSubmit={this.handleRegister} className="checkout__form">
              <div className="row">
                <div className="col-lg-8">
                  <h5>Register</h5>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="checkout__form__input">
                        <p>
                          Email <span>*</span>
                        </p>
                        <input
                          type="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-6"></div> */}
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Name <span>*</span>
                        </p>
                        <input
                          type="text"
                          value={this.state.fullname}
                          onChange={(e) =>
                            this.setState({ fullname: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <p
                          style={{
                            color: "#444444",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          Gender <span className="text-danger">*</span>
                        </p>
                        <>
                          <Select
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: 50,
                                minHeight: 50,
                              }),
                            }}
                            onChange={(e) =>
                              this.setState({
                                gender: e !== null ? e.value : "",
                              })
                            }
                            options={this.state.genderOption}
                            value={this.state.genderOption.filter(
                              (obj) => obj.value === this.state.gender
                            )}
                            placeholder=""
                          />
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                              opacity: 0,
                              height: 0,
                              position: "absolute",
                            }}
                            value={this.state.gender}
                            required
                            onChange={(e) =>
                              this.setState({ gender: e.target.value })
                            }
                          />
                        </>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Account Password <span>*</span>
                        </p>
                        <input
                          type={this.state.typeNewpassword}
                          value={this.state.userpass}
                          onChange={(e) =>
                            this.setState({ userpass: e.target.value })
                          }
                          required
                        />
                        {this.state.typeNewpassword === "password" ? (
                          <i
                            className="fa fa-eye"
                            style={{ cursor: "pointer", marginLeft: "-30px", opacity: '0.3' }}
                            onClick={() =>
                              this.setState({ typeNewpassword: "text" })
                            }
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash"
                            style={{ cursor: "pointer", marginLeft: "-30px", opacity: '0.3' }}
                            onClick={() =>
                              this.setState({
                                typeNewpassword: "password",
                              })
                            }
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Retype Password <span>*</span>
                        </p>
                        <input
                          type={this.state.typeConfpassword}
                          value={this.state.confpassword}
                          onChange={(e) =>
                            this.setState({ confpassword: e.target.value })
                          }
                          required
                        />
                        {this.state.typeConfpassword === "password" ? (
                          <i
                            className="fa fa-eye"
                            style={{ cursor: "pointer", marginLeft: "-30px", opacity: '0.3' }}
                            onClick={() =>
                              this.setState({ typeConfpassword: "text" })
                            }
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash"
                            style={{ cursor: "pointer", marginLeft: "-30px", opacity: '0.3' }}
                            onClick={() =>
                              this.setState({
                                typeNewpassword: "password",
                              })
                            }
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        <p>
                          Address <span>*</span>
                        </p>
                        <textarea
                          style={{
                            // height: '20px',
                            width: '100%',
                            border: '1px solid #e1e1e1',
                            borderRadius: '2px',
                            marginBottom: '25px',
                            fontSize: '14px',
                            paddingLeft: '20px',
                            paddingTop: '15px',
                            color: '#666666',
                          }}
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                          required
                          rows={3}
                        />
                        {/* <input
                          type="text"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                          required
                        /> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <p
                          style={{
                            color: "#444444",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          Province <span className="text-danger">*</span>
                        </p>
                        <>
                          <Select
                            isClearable={true}
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: 50,
                                minHeight: 50,
                              }),
                            }}
                            onChange={(e) => this.handleChangeProvince(e)}
                            // onChange={(e) =>
                            //   this.setState({
                            //     id_province: e !== null ? e.value : "",
                            //   })
                            // }
                            options={this.state.provinceOption}
                            value={this.state.provinceOption.filter(
                              (obj) => obj.value === this.state.id_province
                            )}
                            isDisabled={this.state.showLoadingProvince}
                            isLoading={this.state.showLoadingProvince}
                            placeholder=""
                          />
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                              opacity: 0,
                              height: 0,
                              position: "absolute",
                            }}
                            value={this.state.id_province}
                            required
                            onChange={(e) =>
                              this.setState({ id_province: e.target.value })
                            }
                          />
                        </>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <p
                          style={{
                            color: "#444444",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          City <span className="text-danger">*</span>
                        </p>
                        <>
                          <Select
                            isClearable={true}
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: 50,
                                minHeight: 50,
                              }),
                            }}
                            onChange={(e) => this.handleChangeCity(e)}
                            // onChange={(e) =>
                            //   this.setState({
                            //     id_kabupaten: e !== null ? e.value : "",
                            //   })
                            // }
                            options={this.state.cityOption}
                            value={this.state.cityOption.filter(
                              (obj) => obj.value === this.state.id_kabupaten
                            )}
                            isDisabled={this.state.showLoadingCity}
                            isLoading={this.state.showLoadingCity}
                            placeholder=""
                          />
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                              opacity: 0,
                              height: 0,
                              position: "absolute",
                            }}
                            value={this.state.id_kabupaten}
                            required
                            onChange={(e) =>
                              this.setState({ id_kabupaten: e.target.value })
                            }
                          />
                        </>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <p
                          style={{
                            color: "#444444",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          District <span className="text-danger">*</span>
                        </p>
                        <>
                          <Select
                            isClearable={true}
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: 50,
                                minHeight: 50,
                              }),
                            }}
                            onChange={(e) => this.handleChangeDistrict(e)}
                            // onChange={(e) =>
                            //   this.setState({
                            //     district: e !== null ? e.value : "",
                            //   })
                            // }
                            options={this.state.districtOption}
                            value={this.state.districtOption.filter(
                              (obj) => obj.value === this.state.district
                            )}
                            isDisabled={this.state.showLoadingDistrict}
                            isLoading={this.state.showLoadingDistrict}
                            placeholder=""
                          />
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                              opacity: 0,
                              height: 0,
                              position: "absolute",
                            }}
                            value={this.state.district}
                            required
                            onChange={(e) =>
                              this.setState({ district: e.target.value })
                            }
                          />
                        </>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        style={{
                          marginBottom: "25px",
                        }}
                      >
                        <p
                          style={{
                            color: "#444444",
                            fontWeight: 500,
                            marginBottom: "10px",
                          }}
                        >
                          Village <span className="text-danger">*</span>
                        </p>
                        <>
                          <Select
                            isClearable={true}
                            styles={{
                              control: (base) => ({
                                ...base,
                                height: 50,
                                minHeight: 50,
                              }),
                            }}
                            onChange={(e) => this.handleChangeUrbanVillage(e)}
                            // onChange={(e) =>
                            //   this.setState({
                            //     id_kelurahan: e !== null ? e.value : "",
                            //   })
                            // }
                            options={this.state.urbanVillageOption}
                            value={this.state.urbanVillageOption.filter(
                              (obj) => obj.value === this.state.id_kelurahan
                            )}
                            isDisabled={this.state.showLoadingUrbanVillage}
                            isLoading={this.state.showLoadingUrbanVillage}
                            placeholder=""
                          />
                          <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{
                              opacity: 0,
                              height: 0,
                              position: "absolute",
                            }}
                            value={this.state.id_kelurahan}
                            required
                            onChange={(e) =>
                              this.setState({ id_kelurahan: e.target.value })
                            }
                          />
                        </>
                      </div>
                    </div> */}
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Zip Code <span>*</span>
                        </p>
                        {/* <input
                          type="text"
                          value={this.state.zip_code}
                          onChange={(e) =>
                            this.setState({ zip_code: e.target.value })
                          }
                          required
                        /> */}
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.zip_code}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ zip_code: values.floatValue })
                          }
                          allowEmptyFormatting={false}
                          // format="#### #### #### ####"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="checkout__form__input">
                        <p>
                          Phone <span>*</span>
                        </p>
                        {/* <input
                          type="text"
                          value={this.state.contact}
                          onChange={(e) =>
                            this.setState({ contact: e.target.value })
                          }
                          required
                        /> */}
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.contact}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ contact: values.floatValue })
                          }
                          format="+62 (###) #### #### ####"
                          // mask={"_"}
                          allowEmptyFormatting={false}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="checkout__form__checkbox">
                        <label htmlFor="acc">
                          <p style={{ textAlign: "left" }}>
                            I have read and understand the Privacy and Cookie
                            Policy.
                          </p>
                          <input
                            type="checkbox"
                            id="acc"
                            value={this.state.agreePolicy}
                            onChange={(e) =>
                              this.setState({ agreePolicy: e.target.checked })
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    {this.state.isError ? (
                      <div className="col-lg-12">
                        <Alert variant={"danger"} show={this.state.isError}>
                          <strong>Error!</strong> {this.state.errorMessage}
                          <div className="float-right ml-100">
                            <span
                              aria-hidden="true"
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  isError: false,
                                })
                              }
                            >
                              X
                            </span>
                          </div>
                        </Alert>
                      </div>
                    ) : null}

                    <div className="col-lg-12">
                      <div className="checkout__form__input">
                        {this.state.success ? (
                          <Alert variant={"success"}>
                            {this.state.successMessage}{" "}
                            <a href="/auth/login">
                              click here to <strong>LOGIN</strong>{" "}
                            </a>
                          </Alert>
                        ) : (
                          <button
                            type="submit"
                            style={{
                              width: "100%",
                              cursor: !this.state.agreePolicy
                                ? "not-allowed"
                                : "pointer",
                              opacity: !this.state.agreePolicy ? 0.65 : 100,
                            }}
                            className="submit-btn"
                            disabled={
                              !this.state.agreePolicy || this.state.showLoading
                            }
                          >
                            {this.state.showLoading ? (
                              <div>
                                <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                Loading
                              </div>
                            ) : (
                              "Register"
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        <ToastContainer theme="dark" />
      </>
    );
  }
}