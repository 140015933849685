import React, { Component } from "react";
import { Helmet } from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import ListProduct from "./ListProduct";

export default class ComponentProduct extends Component {
  render() {
    return (
      <>
        <div>
          <Helmet>
            <title>Product - Bikers Dream Administrator</title>
            <meta name="description" content="Product page" />
          </Helmet>

          <BreadCumb title="All Products" />

          <ListProduct {...this.props}/>
        </div>
      </>
    );
  }
}
