/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Helmet from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../http/axios";
import { currencyFormatIndo } from "../../../helper/helper";
import { Spin } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {debounce} from 'lodash';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCart: [],
      totalWeight: 0,
      totalPriceCart: 0,
      quantityCart: {}
    };
  }

  componentDidMount = () => {
    this.getdatacart();
  };

  getdatacart = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.get(`cart`);
      // console.log(res.related);
      if (res.status === 200) {
        var totalCart = 0
        var weightCal = 0
        var Subtotal = 0
        res.data.result.map((item) => {
          totalCart = totalCart + parseInt(item.qty)
          Subtotal += this.getLastPrice(item.last_price)
          // Subtotal += parseInt(item.last_price) + parseInt(item.qty) this.getTotal(item.last_price, item.qty)
          weightCal += parseInt(item.weight)

          this.setState({
            quantityCart: {
              ...this.state.quantityCart,
              ...{ [`${item.id}_${item.produkname}`]: item.qty },
            },
          })
        })
        
        this.props.handleChangeTotalCart(totalCart)
        this.setState({
          listCart: res.data.result,
          totalWeight: weightCal,
          totalPriceCart: Subtotal
        });

        
      } else {
        this.setState({
          listCart: [],
          totalWeight: 0,
        totalPriceCart: 0
        });
        this.props.handleChangeTotalCart(0)
      }

      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        listCart: [],
        totalWeight: 0,
      totalPriceCart: 0,
        showLoading: false,
      });
      this.props.handleChangeTotalCart(0)
    }
  };

  getTotal = (price, qty) => {
    let x = String(price).replaceAll('.', '');
    // console.log(x);
    let total = parseInt(x) * parseInt(qty);
    return total;
  };
  getLastPrice = (price) => {
    let x = String(price).replaceAll('.', '');
    return parseInt(x);
  };

  debounceAction = debounce((dataCart, id) => {
    this.updateCartToDB(dataCart, id)
  }, 500);

  handleChangeQtyInput = (value, record) => {
    var dataCart = {
      uid: record.uid,
      id_variant: record.id_variant ? record.id_variant : "",
      id_option: record.id_option,
      id_variant_second: record.id_variant_second ? record.id_variant_second : '',
      id_option_second: record.id_option_second,
      qty: value,
      note: "",
    };
    this.setState({
      quantityCart: {
        ...this.state.quantityCart,
        ...{ [`${record.id}_${record.produkname}`]: value },
      },
    })

    this.debounceAction(dataCart, record.id)

    // setTimeout(() => {
    // }, 1000);
    // this.updateCartToDB(dataCart, record.id)
  }
  handleChangeQty = (type, record, quantity) => {
    let qty = 0
    if (type === "plus") {
      qty = parseInt(quantity) + 1;
      var dataCart = {
        uid: record.uid,
        id_variant: record.id_variant ? record.id_variant : "",
        id_option: record.id_option,
        id_variant_second: record.id_variant_second ? record.id_variant_second : '',
        id_option_second: record.id_option_second,
        qty: qty,
        note: "",
      };

      this.setState({
        quantityCart: {
          ...this.state.quantityCart,
          ...{ [`${record.id}_${record.produkname}`]: qty },
        },
      })

      // this.updateCartToDB(dataCart, record.id)
      this.debounceAction(dataCart, record.id)

    } else {
      if (parseInt(record.qty) > 1) {
        qty = parseInt(quantity) - 1;

        var dt = {
          uid: record.uid,
          id_variant: record.id_variant ? record.id_variant : "",
          id_option: record.id_option,
          id_variant_second: record.id_variant_second ? record.id_variant_second : '',
          id_option_second: record.id_option_second,
          qty: qty,
          note: "",
        };
  
        this.setState({
          quantityCart: {
            ...this.state.quantityCart,
            ...{ [`${record.id}_${record.produkname}`]: qty },
          },
        })
  
        // this.updateCartToDB(dataCart, record.id)
        this.debounceAction(dt, record.id)
      } else {
        return false
      }
    }
  }

  updateCartToDB = async (dataInput, id) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.put(`cart/${id}`, dataInput);
      // console.log(res.related);
      if (res.status === 200) {
        // console.log(res.data.result);
        this.setState({
          showLoading: false,
        });

        // if (localStorage.getItem("totalCart")) {
        //   var totalBefore = localStorage.getItem("totalCart");
        //   var after = parseInt(totalBefore) + parseInt(this.state.quantity);
        //   localStorage.setItem("totalCart", after);
        // } else {
        //   // var after = totalBefore + this.state.quantity
        //   localStorage.setItem("totalCart", this.state.quantity);
        // }

        // this.props.handleAddTotalCart(parseInt(this.state.quantity));

        toast.success('Success update cart', {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdatacart()
      }
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      this.setState({
        showLoading: false,
      });
      var msg = err ? err.response.data.messages : 'Error invalid'
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT
        });
    }
  };

  deleteCart = async (record) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.delete(`cart/${record.id}`);
      // console.log(res.related);
      if (res.status === 200) {
        // console.log(res.data.result);
        this.setState({
          showLoading: false,
        });

        // if (localStorage.getItem("totalCart")) {
        //   var totalBefore = localStorage.getItem("totalCart");
        //   var after = parseInt(totalBefore) + parseInt(this.state.quantity);
        //   localStorage.setItem("totalCart", after);
        // } else {
        //   // var after = totalBefore + this.state.quantity
        //   localStorage.setItem("totalCart", this.state.quantity);
        // }

        // this.props.handleAddTotalCart(parseInt(this.state.quantity));

        toast.success('Success delete cart', {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdatacart()
      }
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      this.setState({
        showLoading: false,
      });
      var msg = err ? err.response.data.messages : 'Error invalid'
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT
        });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Shopping Cart - Bikers Dream Administrator</title>
          <meta name="description" content="Shopping cart page" />
        </Helmet>

        <BreadCumb title="Shopping Cart" />

        <section className="shop-cart spad">
          <div className="container">
            <Spin spinning={this.state.showLoading}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="shop__cart__table">
                    <table style={{width: '100%'}}>
                      <thead>
                        <tr>
                          <th style={{width: '30%'}}>Product</th>
                          <th className="text-right" style={{width: '20%'}} >Price</th>
                          <th className="text-center" style={{width: '25%'}}>Quantity</th>
                          <th className="text-right" style={{width: '20%'}}>Sub Total</th>
                          <th style={{width: '10%'}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listCart.length ? (
                          this.state.listCart.map((item, x) => (
                            <tr key={x}>
                              <td className="cart__product__item" style={{width: '30%'}}>
                                <div className="cart__product__item__title">
                                  <a
                                    href={`/page/product-detail/${item.catslug}/${item.slugname}`}
                                    target={"_blank"}
                                  >
                                    <h6>{item.produkname}</h6>
                                    <p>{`${item.options ? item.options : ""}${item.options_second ? ", " + item.options_second : ""}`}</p>
                                  </a>
                                </div>
                              </td>
                              <td className="cart__price text-right" style={{width: '20%'}}>
                                Rp. {item.price}
                              </td>
                              <td className="cart__quantity text-center" style={{width: '25%'}}>
                                <div className="pro-qty">
                                  <span
                                    className="dec qtybtn"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() =>
                                      this.handleChangeQty("minus", item, this.state.quantityCart[`${item.id}_${item.produkname}`])
                                    }
                                  >
                                    -
                                  </span>
                                  <input value={this.state.quantityCart[`${item.id}_${item.produkname}`]} onChange={(e) => this.handleChangeQtyInput(e.target.value, item)}/>
                                  {/* <input value={item.qty} onChange={(e) => this.handleChangeQtyInput(e.target.value, item)}/> */}
                                  <span
                                    className="inc qtybtn"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => this.handleChangeQty("plus", item, this.state.quantityCart[`${item.id}_${item.produkname}`])}
                                  >
                                    +
                                  </span>
                                </div>
                              </td>
                              <td className="cart__total text-right" style={{width: '20%'}}>
                                Rp.{" "}
                                {/* {currencyFormatIndo(
                                  this.getTotal(item.last_price, item.qty)
                                )} */}
                                {
                                  item.last_price
                                }
                              </td>
                              <td className="cart__close" style={{width: '10%'}}>
                                <span className="icon_close" onClick={() => this.deleteCart(item)}></span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No cart
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="cart__btn">
                  <a href="/page/shop-product">Continue Shopping</a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="cart__btn update__btn">
                  <a href="#" onClick={() => this.getdatacart()}>
                    <span className="icon_loading"></span> Refresh
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                {/* <div className="discount__content">
                  <h6>Discount codes</h6>
                  <form action="#">
                    <input type="text" placeholder="Enter your coupon code" />
                    <button type="submit" className="site-btn">
                      Apply
                    </button>
                  </form>
                </div> */}
              </div>
              <div className="col-lg-4 offset-lg-2">
                <div className="cart__total__procced">
                  <h6>Cart total</h6>
                  <ul>
                    <li>
                      Subtotal <span>Rp. {currencyFormatIndo(this.state.totalPriceCart)}</span>
                    </li>
                    <li>
                      Total <span>Rp. {currencyFormatIndo(this.state.totalPriceCart)}</span>
                    </li>
                  </ul>
                  <a href="/page/checkout" className="primary-btn">
                    Proceed to checkout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ToastContainer theme="dark" />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalCart: state.totalCart,
    listCart: state.listCart,
  };
};
const mapStateToDispatch = (dispatch) => {
  return {
    handleChangeTotalCart: (valueBaru) =>
      dispatch({ type: "UPDATE_TOTAL_CART", newValue: valueBaru }),
    handleAddTotalCart: (valueBaru) =>
      dispatch({ type: "ADD_TOTAL_CART", newValue: valueBaru }),
    handleUpdateListCart: (valueBaru) =>
      dispatch({ type: "UPDATE_LIST_CART", newValue: valueBaru }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapStateToDispatch)(Cart)
);
