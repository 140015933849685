/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import axios from "../../../http/axios";
import parse from "html-react-parser";
import { Radio, Spin, message } from "antd";
import "antd/dist/antd.min.css";
import "../../../antdstyle.css";
// import { currencyFormatIndo } from "../../../helper/helper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ComponentLogin from "../../auth/ComponentLogin";
// import $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

class DetailProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedList: [],
      dataProduct: {},
      variantOptionFirstSelected: null,
      variantOptionSecondSelected: null,
      showLoadingDetailProduct: false,
      showLoadingStockProduct: false,
      showLoading: false,
      quantity: 1,
      stockProduct: 0,
      type_discount: "0",
      last_price: 0,
      price: 0,
      productname: this.props.match.params.id,
      urlPath: this.props.location.pathname,
      disabledAddCart: true,
      notfound: false,
      isModalVisible: false,
    };
  }

  componentDidMount = () => {
    this.getDetailProduct(
      this.props.match.params.category,
      this.props.match.params.id
    );
  };

  getDetailProduct = async (category, slugname) => {
    this.setState({
      showLoadingDetailProduct: true,
    });
    try {
      const res = await axios.get(`product-detail/${category}/${slugname}`);
      // console.log(res.related);
      if (res.status === 200) {
        var dataProduct = res.data.result.data_product;
        if (Array.isArray(dataProduct)) {
          this.setState({
            notfound: true,
          });
        } else {
          if (!Array.isArray(dataProduct.variant)) {
            // console.log(dataProduct.variant);
            var option1 = dataProduct.variant.options;
            if (option1.length) {
              this.setState({
                variantOptionFirstSelected: option1[0].id_option,
              });

              if (!Array.isArray(dataProduct.variant_second)) {
                // console.log(dataProduct.variant_second);
                var option2 = dataProduct.variant_second.options_second;
                if (option2.length) {
                  this.setState({
                    variantOptionSecondSelected: option2[0].id_option_second,
                  });
                  this.getStockProduct(
                    dataProduct.uid,
                    option1[0].id_option,
                    option2[0].id_option_second
                  );
                }
              } else {
                this.getStockProduct(
                  dataProduct.uid,
                  option1[0].id_option,
                  null
                );
              }
            }
          }

          this.setState({
            dataProduct,
            productname: dataProduct.productname,
            stockProduct: dataProduct.stock,
            type_discount: dataProduct.type_discount,
            last_price: dataProduct.last_price,
            price: dataProduct.price,
            disabledAddCart: dataProduct.stock === "0" ? true : false,
            notfound: false,
          });
        }
      } else {
        this.setState({
          dataProduct: {},
          stockProduct: 0,
          type_discount: "0",
          last_price: 0,
          price: 0,
          disabledAddCart: true,
          notfound: true,
        });
      }
      this.setState({
        relatedList: res.data.related ? res.data.related : [],
        showLoadingDetailProduct: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        relatedList: [],
        dataProduct: {},
        stockProduct: 0,
        type_discount: "0",
        last_price: 0,
        price: 0,
        showLoadingDetailProduct: false,
        disabledAddCart: true,
        notfound: true,
      });
    }
  };
  getStockProduct = async (uid, id_option = null, id_option_second = null) => {
    this.setState({
      showLoadingStockProduct: true,
    });
    try {
      const res = await axios.get(`product-stock`, {
        params: {
          uid: uid,
          id_option: id_option ? id_option : null,
          id_option_second: id_option_second ? id_option_second : null,
        },
      });
      // console.log(res.related);
      if (res.status === 200) {
        var dataStock = res.data.result;
        // console.log("dataStock", dataStock);

        this.setState({
          stockProduct: dataStock.stock,
          type_discount: dataStock.type_discount,
          last_price: dataStock.last_price,
          price: dataStock.price,
          disabledAddCart: dataStock.stock === "0" ? true : false,
        });
      } else {
        this.setState({
          dataProduct: {},
          stockProduct: 0,
          type_discount: "0",
          last_price: 0,
          price: 0,
          disabledAddCart: true,
        });
      }
      this.setState({
        showLoadingStockProduct: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        stockProduct: 0,
        type_discount: "0",
        last_price: 0,
        price: 0,
        showLoadingStockProduct: false,
        disabledAddCart: true,
      });
    }
  };

  changeVariantFirst = (e) => {
    // console.log(e);
    this.setState({
      variantOptionFirstSelected: e.target.value,
    });
    this.getStockProduct(
      this.state.dataProduct.uid,
      e.target.value,
      this.state.variantOptionSecondSelected
    );
  };
  changeVariantSecond = (e) => {
    // console.log(e);
    this.setState({
      variantOptionSecondSelected: e.target.value,
    });
    this.getStockProduct(
      this.state.dataProduct.uid,
      this.state.variantOptionFirstSelected,
      e.target.value
    );
  };

  handleChangeQty = (type) => {
    if (type === "plus") {
      var qty = this.state.quantity + 1;
      this.setState({
        quantity: qty,
      });
    } else {
      if (this.state.quantity > 1) {
        var quantity = this.state.quantity - 1;
        this.setState({
          quantity,
        });
      }
    }
  };

  handleAddCart = () => {
    if (this.state.disabledAddCart) {
      return false;
    }

    // const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // if (localStorage.getItem('userInfo')) {
    //   if (!Array.isArray(this.state.dataProduct.variant)) {
    //     if(this.state.variantOptionFirstSelected === "") {
    //       message.error('Please select ' + this.state.dataProduct.variant.variant);
    //     }
    //     else if(!Array.isArray(this.state.dataProduct.variant_second)) {
    //       // alert('tes')
    //       if(this.state.variantOptionSecondSelected === "") {
    //         message.error('Please select ' + this.state.dataProduct.variant_second.variant_second);
    //       }  else {
    //         this.actionAdd()
    //       }
    //     } else {
    //       this.actionAdd()
    //     }
    //   } else {
    //     if(!Array.isArray(this.state.dataProduct.variant_second)) {
    //       // alert('tes')
    //       if(this.state.variantOptionSecondSelected === "") {
    //         message.error('Please select ' + this.state.dataProduct.variant_second.variant_second);
    //       }  else {
    //         this.actionAdd()
    //       }
    //     } else {
    //       this.actionAdd()
    //     }
    //   }
    // } else {
    //   // console.log(this.props.param);
    //   this.setState({
    //     isModalVisible: true
    //   })
    // }
    if (!Array.isArray(this.state.dataProduct.variant)) {
      if (this.state.variantOptionFirstSelected === "") {
        message.error(
          "Please select " + this.state.dataProduct.variant.variant
        );
      } else if (!Array.isArray(this.state.dataProduct.variant_second)) {
        // alert('tes')
        if (this.state.variantOptionSecondSelected === "") {
          message.error(
            "Please select " +
              this.state.dataProduct.variant_second.variant_second
          );
        } else {
          this.actionAdd();
        }
      } else {
        this.actionAdd();
      }
    } else {
      if (!Array.isArray(this.state.dataProduct.variant_second)) {
        // alert('tes')
        if (this.state.variantOptionSecondSelected === "") {
          message.error(
            "Please select " +
              this.state.dataProduct.variant_second.variant_second
          );
        } else {
          this.actionAdd();
        }
      } else {
        this.actionAdd();
      }
    }
  };

  actionAdd = () => {
    // const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (localStorage.getItem("userInfo")) {
      let dataProduct = this.state.dataProduct;
      var dataCart = {
        uid: dataProduct.uid,
        id_variant: Array.isArray(dataProduct.variant)
          ? ""
          : dataProduct.variant.id_variant,
        id_option: this.state.variantOptionFirstSelected,
        id_variant_second: Array.isArray(dataProduct.variant_second)
          ? ""
          : dataProduct.variant_second.id_variant_second,
        id_option_second: this.state.variantOptionSecondSelected,
        qty: this.state.quantity,
        note: "",
      };

      this.addCartToDB(dataCart);

      // var dataCart = []
      // var fixListCart = []
      // var dd = []
      // dataCart.push(cart)

      // if (localStorage.getItem('listCart')) {
      //   console.log(dataCart, 'dataCart');
      //   var xd = JSON.parse(localStorage.getItem('listCart'))
      //   var index = xd.findIndex(obj => obj.uid === dataCart[0].uid);

      //   if (index === -1) {
      //     dd = xd.concat(dataCart)
      //   } else {
      //     xd[index].qty = parseInt(xd[index].qty) + parseInt(this.state.quantity)
      //     dd = xd
      //   }

      //   console.log(dd, 'dd');
      //   fixListCart = dd
      //   localStorage.setItem('listCart', JSON.stringify(dd))
      // } else {
      //   fixListCart = dataCart
      //   localStorage.setItem('listCart', JSON.stringify(dataCart))
      // }

      // if (localStorage.getItem('totalCart')) {
      //   var totalBefore = localStorage.getItem('totalCart')
      //   var after = parseInt(totalBefore) + parseInt(this.state.quantity)
      //   localStorage.setItem('totalCart', after)
      // } else {
      //   // var after = totalBefore + this.state.quantity
      //   localStorage.setItem('totalCart', this.state.quantity)
      // }

      // this.props.handleAddTotalCart(parseInt(this.state.quantity))
      // this.props.handleUpdateListCart(fixListCart)
    } else {
      //   // console.log(this.props.param);
      this.setState({
        isModalVisible: true,
      });
    }
  };

  handleCancle = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  addCartToDB = async (dataInput) => {
    this.setState({
      showLoading: true,
    });
    try {
      const res = await axios.post(`cart`, dataInput);
      // console.log(res.related);
      if (res.status === 200) {
        // console.log(res.data.result);
        this.setState({
          showLoading: false,
        });

        // if (localStorage.getItem("totalCart")) {
        //   var totalBefore = localStorage.getItem("totalCart");
        //   var after = parseInt(totalBefore) + parseInt(this.state.quantity);
        //   localStorage.setItem("totalCart", after);
        // } else {
        //   // var after = totalBefore + this.state.quantity
        //   localStorage.setItem("totalCart", this.state.quantity);
        // }

        // this.props.handleAddTotalCart(parseInt(this.state.quantity));

        toast.success("Success add cart", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/page/shopping-cart";
        }, 500);
      }
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  replacePrice = (price) => {
    var cc = price.replace(",", ".");
    return cc;
  };

  render() {
    const { dataProduct, showLoadingDetailProduct } = this.state;
    // console.log(dataProduct);
    return (
      <>
        <Helmet>
          <title>Detail Product - Bikers Dream Administrator</title>
          <meta name="description" content="Detail product page" />
        </Helmet>

        {/* {showLoadingDetailProduct ? (
            <div className="loader"></div>
          ) : ( */}
        <Spin spinning={showLoadingDetailProduct || this.state.showLoading}>
          <>
            <BreadCumb
              title="Detail Product"
              ProductName={this.state.productname}
            />

            {this.state.notfound ? (
              <section className="product-details spad">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        className="product__item__pic set-bg"
                        style={{
                          backgroundImage: `url(https://tradebharat.in/assets/catalogue/img/no-product-found.png)`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          // backgroundSize: "270px 360px",
                        }}
                      ></div>
                    </div>
                    <div className="col-lg-12">
                      <div className="text-center">
                        <a
                          href="/page/shop-product"
                          style={{
                            color: "black",
                            textDecorationColor: "red",
                            textDecoration: "underline",
                          }}
                        >
                          Back to all product
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="product-details spad">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="product__details__pic">
                        <div>
                          <Carousel dynamicHeight={true} infiniteLoop={true}>
                            {dataProduct.images && dataProduct.images.length ? (
                              dataProduct.images.map((img, y) =>
                                img.url_video !== null ? (
                                  <div>
                                    <iframe
                                      width={"100%"}
                                      height="315"
                                      frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowFullScreen
                                      src={img.url_video}
                                      // src={img.url_video}
                                    ></iframe>
                                  </div>
                                ) : (
                                  <div key={y}>
                                    <img
                                      // data-hash={img.uid}
                                      // className="product__big__img"
                                      src={img.url}
                                      alt=""
                                      // width={413}
                                      // height={500}
                                      style={{
                                        // height: "550px",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                )
                              )
                            ) : (
                              <div style={{ height: "500px" }}>
                                <img
                                  // data-hash="product-none"
                                  // className="product__big__img"
                                  src="https://static.hertz-audio.com/media/2021/05/no-product-image.png"
                                  // height={500}
                                  // width={413}
                                  style={{
                                    // height: "550px",
                                    width: "100%",
                                  }}
                                  alt=""
                                />
                              </div>
                            )}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="product__details__text">
                        <h3>
                          {/* <h3 style={{ fontFamily: "Rufner" }}> */}
                          {this.state.productname}
                          {/* <span>Brand: SKMEIMore Men Watches from SKMEI</span> */}
                          <span>Category: {dataProduct.categoryname}</span>
                        </h3>
                        {/* <div className="rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <span>( 138 reviews )</span>
                          </div> */}
                        <div className="product__details__price">
                          {this.state.type_discount !== "0" ? (
                            <>
                              Rp.{" "}
                              {this.state.last_price
                                ? this.state.last_price
                                : 0}{" "}
                              <span>
                                {this.state.price ? this.state.price : 0}
                              </span>
                            </>
                          ) : (
                            <>
                              Rp. {this.state.price ? this.state.price : 0}
                              {/* Rp. {this.state.price ? this.replacePrice(this.state.price) : 0} */}
                            </>
                          )}
                        </div>
                        {/* <p>
                            Nemo enim ipsam voluptatem quia aspernatur aut odit
                            aut loret fugit, sed quia consequuntur magni lores eos
                            qui ratione voluptatem sequi nesciunt.
                          </p> */}
                        <div className="product__details__button">
                          <div className="quantity">
                            <span>Quantity:</span>
                            <div className="pro-qty">
                              <span
                                className="dec qtybtn"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => this.handleChangeQty("minus")}
                              >
                                -
                              </span>
                              <input value={this.state.quantity} />
                              <span
                                className="inc qtybtn"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => this.handleChangeQty("plus")}
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="cart-btn"
                            disabled={this.state.disabledAddCart}
                            style={{
                              cursor: this.state.disabledAddCart
                                ? "not-allowed"
                                : "pointer",
                              background: this.state.disabledAddCart
                                ? "#fa6969"
                                : "#ca1515",
                            }}
                            onClick={() => this.handleAddCart()}
                          >
                            <span className="icon_bag_alt"></span> Add to cart
                          </button>
                        </div>
                        <div>
                          <table
                            style={{ border: "none" }}
                            border={0}
                            cellPadding={5}
                          >
                            <tr>
                              <td>
                                <b>Availability: </b>
                              </td>
                              <td>
                                <Spin
                                  spinning={this.state.showLoadingStockProduct}
                                >
                                  {parseInt(this.state.stockProduct) > 0
                                    ? `In Stock (${this.state.stockProduct})`
                                    : "Out of Stock"}
                                </Spin>
                              </td>
                            </tr>
                            {Object.keys(dataProduct).length ? (
                              !Array.isArray(dataProduct.variant) ? (
                                <tr>
                                  <td>
                                    <b>
                                      Available {dataProduct.variant.variant}:{" "}
                                    </b>
                                  </td>
                                  <td>
                                    <Radio.Group
                                      onChange={this.changeVariantFirst}
                                      buttonStyle="solid"
                                      value={
                                        this.state.variantOptionFirstSelected
                                      }
                                    >
                                      {/* defaultValue={dataProduct.variant.options[0].id_option}> */}
                                      {dataProduct.variant.options.map(
                                        (opt, xd) => (
                                          <Radio.Button
                                            style={{ width: "fit-content" }}
                                            value={opt.id_option}
                                            key={xd}
                                          >
                                            {opt.option}
                                          </Radio.Button>
                                        )
                                      )}
                                    </Radio.Group>
                                  </td>
                                </tr>
                              ) : null
                            ) : null}
                            {Object.keys(dataProduct).length ? (
                              !Array.isArray(dataProduct.variant_second) ? (
                                <tr>
                                  <td>
                                    <b>
                                      Available{" "}
                                      {
                                        dataProduct.variant_second
                                          .variant_second
                                      }
                                      :
                                    </b>
                                  </td>
                                  <td>
                                    <Radio.Group
                                      onChange={this.changeVariantSecond}
                                      buttonStyle="solid"
                                      value={
                                        this.state.variantOptionSecondSelected
                                      }
                                    >
                                      {dataProduct.variant_second.options_second.map(
                                        (opt, xd) => (
                                          <Radio.Button
                                            style={{ width: "fit-content" }}
                                            value={opt.id_option_second}
                                            key={xd}
                                          >
                                            {opt.option_second}
                                          </Radio.Button>
                                        )
                                      )}
                                    </Radio.Group>
                                  </td>
                                </tr>
                              ) : null
                            ) : null}
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="product__details__tab">
                        <ul className="nav nav-tabs" role="tablist">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#tabs-1"
                              role="tab"
                            >
                              Description
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="tabs-1"
                            role="tabpanel"
                          >
                            <h6>Description</h6>
                            <div>{parse(String(dataProduct.description))}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {relatedList.length ? (
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <div className="related__title">
                            <h5>RELATED PRODUCTS</h5>
                          </div>
                        </div>
                        {relatedList.map((item, idx) => (
                          <div className="col-lg-3 col-md-4 col-sm-6" key={idx}>
                            <div className="product__item">
                              <div
                                className="product__item__pic set-bg"
                                style={{
                                  backgroundImage: `url(${
                                    item.sampul
                                      ? item.sampul
                                      : "https://static.hertz-audio.com/media/2021/05/no-product-image.png"
                                  })`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "270px 360px",
                                }}
                              >
                                {item.stock === "Habis" ? (
                                  <div className="label sale">OUT OF STOCK</div>
                                ) : null}
  
                                <ul className="product__hover">
                                  <li>
                                    <a
                                      href={
                                        item.sampul
                                          ? item.sampul
                                          : "https://static.hertz-audio.com/media/2021/05/no-product-image.png"
                                      }
                                      className="image-popup"
                                      target={"_blank"}
                                    >
                                      <span className="arrow_expand"></span>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="product__item__text">
                                <h6>
                                  <a href="#">
                                    {item.productname.length > 26
                                      ? item.productname.substr(0, 24) + "..."
                                      : item.productname}
                                  </a>
                                </h6>
                                <div className="product__price">
                                  {item.type_discount !== "0" ? (
                                    <>
                                      Rp. {item.last_price ? item.last_price : 0}{" "}
                                      <span>{item.price ? item.price : 0}</span>
                                    </>
                                  ) : (
                                    <>Rp. {item.price ? item.price : 0}</>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null} */}
                </div>
              </section>
            )}
          </>
        </Spin>

        <ComponentLogin {...this.state} handleCancle={this.handleCancle} />
        <ToastContainer theme="dark" />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalCart: state.totalCart,
    listCart: state.listCart,
  };
};
const mapStateToDispatch = (dispatch) => {
  return {
    handleChangeTotalCart: (valueBaru) =>
      dispatch({ type: "UPDATE_TOTAL_CART", newValue: valueBaru }),
    handleAddTotalCart: (valueBaru) =>
      dispatch({ type: "ADD_TOTAL_CART", newValue: valueBaru }),
    handleUpdateListCart: (valueBaru) =>
      dispatch({ type: "UPDATE_LIST_CART", newValue: valueBaru }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapStateToDispatch)(DetailProduct)
);
