/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Helmet from "react-helmet";
import BreadCumb from "../../component/BreadCumb";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../../http/axios";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Radio, Space, Spin, Switch } from "antd";
import Select from "react-select";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { currencyFormatIndo } from "../../../helper/helper";
import { Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";

const userInfo = localStorage.getItem("userInfo") ? (
  JSON.parse(localStorage.getItem("userInfo"))
) : (
  <Redirect to={"/auth/login"} />
);

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listCart: [],
      bankOption: [],
      listAddress: [],
      AddressDefault: [],
      shippingMethodOption: [
        {
          label: "TIKI",
          value: "tiki",
        },
      ],
      shippingMethod: "tiki",
      showLoadingAddress: false,
      showAddressModal: false,
      showLoadingBank: false,
      showLoadingCart: false,
      showLoadingshippingMethod: false,
      modalDialogConfirmation: false,
      showLoading: false,
      id_rek: "",
      id_address: "",
      total_weigth: 0,
      total_shipping: 0,
      additional: 0,
      Newadditional: 0,
      serviceShipping: "",
      data: [],

      number_account: "",
      an: "",
      name_bank: "",
      address_origin: "",

      courier_code: "",
      courier_name: "",
      courier_service: "",
      courier_service_description: "",

      totalPriceCart: 0,
      showLoadingCekOngkir: false,

      isModalVisible: false,
      isDisabled: false,
      showLoadingProvince: false,
      showLoadingCity: false,
      provinceOption: [],
      cityOption: [],

      id_user: userInfo.id,
      id_kabupaten: "",
      id_address_new: "",
      id_province: "",
      address: "",
      receiver: "",
      phone: "",
      zip_code: "",
      default: "Y",

      isUpdateForm: false,
    };
  }

  componentDidMount = () => {
    this.getAddressUser();
    this.getBankOption();
    this.getdatacart();
    this.getAddressOrigin();
    this.getAdditionalOngkir();
    this.getProvince();
  };

  getAddressOrigin = async () => {
    try {
      const res = await axios.get("setting/address_origin");
      if (res.status === 200) {
        if (res.data.result !== "") {
          var data = res.data.result;
          this.setState({
            address_origin: data.value,
          });
        } else {
          this.setState({
            address_origin: "",
          });
        }
      } else {
        this.setState({
          address_origin: "",
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        address_origin: "",
      });
    }
  };
  getAdditionalOngkir = async () => {
    try {
      const res = await axios.get("setting/add_ongkir");
      if (res.status === 200) {
        if (res.data.result !== "") {
          var data = res.data.result;
          this.setState({
            additional: data.value,
          });
        } else {
          this.setState({
            additional: 0,
          });
        }
      } else {
        this.setState({
          additional: 0,
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        address_origin: "",
      });
    }
  };

  getdatacart = async () => {
    this.setState({
      showLoadingCart: true,
    });
    try {
      const res = await axios.get(`cart`);
      // console.log(res.related);
      if (res.status === 200) {
        var listCart = res.data.result;
        var weightCal = 0;
        var Subtotal = 0;
        res.data.result.map((item) => {
          Subtotal += this.getLastPrice(item.last_price);
          // Subtotal += parseInt(item.last_price) + parseInt(item.qty) this.getTotal(item.last_price, item.qty)
          weightCal += parseInt(item.weight);
        });
        this.setState({
          listCart,
          total_weigth: weightCal,
          totalPriceCart: Subtotal,
        });
      } else {
        this.setState({
          listCart: [],
          total_weigth: 0,
          totalPriceCart: 0,
        });
      }

      this.setState({
        showLoadingCart: false,
      });
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        listCart: [],
        total_weigth: 0,
        totalPriceCart: 0,
        showLoadingCart: false,
      });
    }
  };

  getAddressUser = async () => {
    this.setState({
      showLoadingAddress: true,
    });
    try {
      const res = await axios.get(`address-options/${userInfo.id}`);
      // console.log(res);
      if (res.status === 200) {
        var listAddress = [];
        var Address = [];
        var dt = res.data.result.sort(function (a, b) {
          return b.id - a.id;
        });

        if (dt.length) {
          // console.log('dt', dt);
          listAddress = dt.map((item) => {
            return {
              id: item.id,
              id_user: item.id_user,
              address: item.address,
              receiver: item.receiver,
              zip_code: item.zip_code,
              default: item.default,
              phone: item.phone,
              city_id: item.raja_ongkir ? item.raja_ongkir.city_id : "",
              city_name: item.raja_ongkir ? item.raja_ongkir.city_name : "",
              province_id: item.raja_ongkir ? item.raja_ongkir.province_id : "",
              province: item.raja_ongkir ? item.raja_ongkir.province : "",
            };
          });

          Address = listAddress.filter((item) => item.default === "Y");
        }

        this.setState({
          listAddress,
          AddressDefault: Address.length ? Address[0] : [],
          id_address: Address.length ? Address[0].id : 0,
        });

        // console.log("cek id", Address.length ? Address[0].city_id : "");

        this.checkOngkir(
          Address.length ? Address[0].city_id : "",
          this.state.total_weigth,
          this.state.shippingMethod,
          this.state.address_origin
        );
      } else {
        this.setState({
          listAddress: [],
          AddressDefault: [],
          id_address: 0,
        });
      }
      this.setState({
        showLoadingAddress: false,
      });
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      this.setState({
        listAddress: [],
        AddressDefault: [],
        showLoadingAddress: false,
        id_address: 0,
      });
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getBankOption = async () => {
    this.setState({
      showLoadingBank: true,
      value: "",
    });

    try {
      const res = await axios.get("content/rekening-bank");
      if (res.status === 200) {
        // var data = res.data.result
        var data = res.data.result.sort(function (a, b) {
          return a.name_bank.localeCompare(b.name_bank);
        });

        var bankOption = data.map((item) => {
          return {
            label: item.name_bank,
            value: item.id,
            number_account: item.number_account,
            an: item.an,
            name_bank: item.name_bank,
            link_icon: item.link_icon,
          };
        });
        this.setState({
          bankOption,
          id_rek: bankOption[0].value,
        });
      } else {
        this.setState({
          bankOption: [],
          id_rek: "",
        });
      }
      this.setState({
        bankOption,
        showLoadingBank: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingBank: false,
        bankOption: [],
        id_rek: "",
      });
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  openModal = () => {
    this.setState({
      showAddressModal: true,
    });
  };

  changeDefault = async (record) => {
    this.setState({
      showLoadingAddress: true,
    });
    try {
      let res = {};
      res = await axios.get(
        "address-set-default/" + userInfo.id + "/" + record.id
      );
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showAddressModal: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // this.toggleModal("showAddressDefaultModal");
        this.getAddressUser();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingAddress: false,
      });
    }
  };

  checkOngkir = async (destination, weight, courier, origin) => {
    this.setState({
      showLoadingCekOngkir: true,
    });
    try {
      let res = {};
      res = await axios.get("ongkir/cost", {
        params: {
          destination: destination,
          origin: origin,
          weight: weight,
          courier: courier,
        },
      });
      // console.log(res);

      if (res.status === 200) {
        var result = res.data.rajaongkir.results;
        if (result.length) {
          var getcost = result[0].costs;
          this.setState({
            courier_code: result[0].code,
            courier_name: result[0].name,
          });
          if (getcost.length) {
            // console.log("getcost ketemu");
            var findReg = getcost.filter((item) => item.service === "REG");

            if (findReg.length) {
              // console.log("findReg ketemu");
              var total_shipping = findReg[0].cost.length
                ? findReg[0].cost[0].value
                : 0;
              var serviceShipping = findReg[0].service
                ? findReg[0].service
                : "";
              this.setState({
                total_shipping,
                serviceShipping,
                courier_service: findReg[0].service,
                courier_service_description: findReg[0].description,
              });
            } else {
              // console.log("findReg tidak ketemu");
              var totalcost = getcost[0].cost.length
                ? getcost[0].cost[0].value
                : 0;
              var serv = getcost[0].service ? getcost[0].service : "";
              this.setState({
                total_shipping: totalcost,
                serviceShipping: serv,
                courier_service: getcost[0].service ? getcost[0].service : "",
                courier_service_description: getcost[0].description
                  ? getcost[0].description
                  : "",
              });
            }
          } else {
            console.log("getcost tidak ketemu");
            this.setState({
              total_shipping: 0,
              serviceShipping: "",
            });
          }
        } else {
          this.setState({
            total_shipping: 0,
            serviceShipping: "",
            courier_code: "",
            courier_name: "",
            courier_service: "",
            courier_service_description: "",
          });
        }
      }

      this.setState({
        showLoadingCekOngkir: false,
      });
    } catch (err) {
      // Handle Error Here
      console.log(err);
      this.setState({
        total_shipping: 0,
        serviceShipping: "",
        courier_code: "",
        courier_name: "",
        courier_service: "",
        courier_service_description: "",
        showLoadingCekOngkir: false,
      });
    }
  };

  getTotal = (price, qty) => {
    let x = price.replaceAll(".", "");
    let total = parseInt(x) * parseInt(qty);
    return total;
  };

  getLastPrice = (price) => {
    let x = String(price).replaceAll(".", "");
    return parseInt(x);
  };

  getTotalOrder = (subtotal, shipping, additional) => {
    let total = parseInt(subtotal) + parseInt(shipping) + parseInt(additional);
    return total;
  };

  processOrder = async (e) => {
    e.preventDefault();

    var dataCart = [];
    this.state.listCart.map((item) => {
      var obj = {
        id_cart: item.id,
      };
      dataCart.push(obj);
    });
    var total_shipping =
      parseInt(this.state.total_shipping) + parseInt(this.state.additional);
    var dataInput = {
      id_rek: String(this.state.id_rek),
      id_address: String(this.state.id_address),
      total_weigth: String(this.state.total_weigth),
      total_shipping: String(total_shipping),
      data: dataCart,
      // courier_code: String(this.state.courier_code),
      // courier_name: String(this.state.courier_name),
      // courier_service: String(this.state.courier_service),
      // courier_service_description: String(
      //   this.state.courier_service_description
      // ),
    };

    try {
      const res = await axios.get("setting/add_ongkir");
      if (res.status === 200) {
        if (res.data.result !== "") {
          var data = res.data.result;
          var additional = data.value;
          if (parseInt(additional) === parseInt(this.state.additional)) {
            this.executingOrder(dataInput);
          } else {
            this.toggleModal("modalDialogConfirmation");
            this.setState({
              Newadditional: additional,
            });
          }
        }
      } else {
        toast.error("Transaction Invalid", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleExecuteOrder = async (e) => {
    e.preventDefault();
    var dataCart = [];
    this.state.listCart.map((item) => {
      var obj = {
        id_cart: item.id,
      };
      dataCart.push(obj);
    });
    var total_shipping =
      parseInt(this.state.total_shipping) + parseInt(this.state.Newadditional);
    var dataInput = {
      id_rek: String(this.state.id_rek),
      id_address: String(this.state.id_address),
      total_weigth: String(this.state.total_weigth),
      total_shipping: String(total_shipping),
      data: dataCart,
      // courier_code: String(this.state.courier_code),
      // courier_name: String(this.state.courier_name),
      // courier_service: String(this.state.courier_service),
      // courier_service_description: String(
      //   this.state.courier_service_description
      // ),
    };
    this.executingOrder(dataInput);
  };

  executingOrder = async (dataInput) => {
    this.setState({
      showLoading: true,
      isDisabled: true,
    });

    // console.log(dataInput);
    try {
      const res = await axios.post(`order-list`, dataInput);
      // console.log(res.related);
      if (res.status === 200) {
        // console.log(res.data.result);
        this.setState({
          showLoading: false,
          isDisabled: false,
          modalDialogConfirmation: false,
        });

        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.href = "/page/order-list";
        }, 500);
      }
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      this.setState({
        showLoading: false,
        isDisabled: false,
      });
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  openModalAddress = () => {
    this.toggleModal("isModalVisible");
    this.setState({
      isUpdateForm: false,
      id_address_new: "",
      address: "",
      id_province: "",
      id_kabupaten: "",
      receiver: "",
      default: "Y",
      zip_code: "",
      phone: "",

      cityOption: [],
      isDisabled: false,
    });
  };

  editDataAddress = (record) => {
    this.toggleModal("isModalVisible");
    this.getCity(record.province_id);
    this.setState({
      isUpdateForm: true,
      id_address_new: record.id,
      address: record.address,
      id_province: record.province_id,
      id_kabupaten: record.city_id,
      receiver: record.receiver,
      default: record.default,
      zip_code: record.zip_code,
      phone: this.getNumberPhoneReverse(record.phone),
      isDisabled: false,
    });
  };

  getNumberPhone = (numb) => {
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd !== "0") {
      xd = `0${numb}`;
    }
    return xd;
  };
  getNumberPhoneReverse = (numb) => {
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd === "0") {
      xd = numb.substring(1);
    }
    return xd;
  };

  handleSubmitAddress = async (e) => {
    e.preventDefault();
    this.setState({
      isDisabled: true,
    });

    var dataInput = {
      id_user: this.state.id_user,
      address: this.state.address,
      id_province: this.state.id_province,
      id_kabupaten: this.state.id_kabupaten,
      receiver: this.state.receiver,
      default: this.state.default,
      zip_code: this.state.zip_code,
      phone: this.getNumberPhone(this.state.phone),
    };

    try {
      let res = {};
      if (this.state.isUpdateForm) {
        res = await axios.put(
          "address-options/" + this.state.id_address_new,
          dataInput
        );
      } else {
        res = await axios.post("address-options", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          isDisabled: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getAddressUser();

        this.toggleModal("isModalVisible");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        isDisabled: false,
      });
    }
  };

  handleChangeProvince = (e) => {
    if (e !== null) {
      this.setState({
        id_province: e.value,
      });
      this.getCity(e.value);
    } else {
      this.setState({
        id_province: "",
        id_kabupaten: "",
        cityOption: [],
      });
    }
  };
  handleChangeCity = (e) => {
    if (e !== null) {
      this.setState({
        id_kabupaten: e.value,
      });
    } else {
      this.setState({
        id_kabupaten: "",
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  getProvince = async () => {
    this.setState({
      showLoadingProvince: true,
    });
    try {
      const res = await axios.get("ongkir/wilayah/province");
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var provinceOption = dt.map((item) => {
          return {
            value: item.province_id,
            label: item.province,
          };
        });

        this.setState({
          provinceOption,
        });
      } else {
        this.setState({
          provinceOption: [],
        });
      }
      this.setState({
        showLoadingProvince: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingProvince: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getCity = async (id) => {
    this.setState({
      showLoadingCity: true,
    });
    try {
      const res = await axios.get("ongkir/wilayah/city/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var cityOption = dt.map((item) => {
          return {
            value: item.city_id,
            label: item.city_name,
            province_id: item.province_id,
            province: item.province,
            type: item.type,
            postal_code: item.postal_code,
          };
        });

        this.setState({
          cityOption,
        });
      } else {
        this.setState({
          cityOption: [],
        });
      }
      this.setState({
        showLoadingCity: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingCity: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  render() {
    const { AddressDefault } = this.state;
    return (
      <>
        <Helmet>
          <title>Checkout - Bikers Dream Administrator</title>
          <meta name="description" content="Checkout page" />
        </Helmet>

        <BreadCumb title="Checkout" />
        <Spin spinning={this.state.showLoading}>
          <section className="checkout spad">
            <div className="container">
              <form onSubmit={this.processOrder} className="checkout__form">
                <div className="row">
                  <div className="col-lg-7">
                    <Spin spinning={this.state.showLoadingAddress}>
                      <h5>Shipping Address</h5>
                      {Array.isArray(AddressDefault) ? (
                        // <p>Default address not found</p>
                        <a
                        className="ml-4"
                        href="#"
                        onClick={() => this.openModalAddress()}
                      >
                        + Add Address
                      </a>
                      ) : (
                        <>
                          <p className="mb-0">
                            <b className="mr-4">{AddressDefault.receiver}</b>
                            <a href="#" onClick={() => this.openModal()}>
                              Change
                            </a>
                          </p>
                          <p className="mb-0">{AddressDefault.address}</p>
                          <p className="mb-0">{AddressDefault.province}</p>
                          <p className="mb-0">{AddressDefault.city_name}</p>
                          {/* <p className="mb-0">{AddressDefault.kecamatan}</p>
                          <p className="mb-0">{AddressDefault.kelurahan}</p> */}
                          <p className="mb-0">{AddressDefault.zip_code}</p>
                          <p className="mb-10">{AddressDefault.phone}</p>
                        </>
                      )}
                    </Spin>

                    <h5 style={{ marginTop: "20px" }}>Payment Method</h5>
                    <div className="form-group">
                      <div style={{ marginBottom: "10px" }}>
                        <Radio.Group
                          onChange={
                            (e) =>
                              this.setState({
                                id_rek: e.target.value,
                              })
                            // console.log(e)
                          }
                          value={this.state.id_rek}
                        >
                          <Space direction="vertical">
                            {this.state.bankOption.length
                              ? this.state.bankOption.map((item, keyradio) => (
                                  <Radio value={item.value} key={`radio_${keyradio}`}>
                                    <div style={{ display: 'flex', flexWrap: "nowrap" }}>
                                      <div style={{margin: '5px'}}>
                                      <img
                                        src={item.link_icon}
                                        alt=""
                                        className="img-social"
                                        style={{
                                          // borderRadius: "50%",
                                          height: "50px",
                                          width: "60px",
                                        }}
                                      />
                                      </div>
                                      <div style={{margin: '5px', padding: '15px'}}>
                                      <h6>
                                        {item.name_bank} - {item.number_account}{" "}
                                        a/n {item.an}
                                      </h6>
                                      </div>
                                    </div>
                                  </Radio>
                                ))
                              : null}
                          </Space>
                        </Radio.Group>
                        {/* <Select
                          // isClearable={true}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 50,
                              minHeight: 50,
                            }),
                          }}
                          onChange={(e) =>
                            this.setState({
                              id_rek: e !== null ? e.value : "",
                              name_bank: e !== null ? e.name_bank : "",
                              an: e !== null ? e.an : "",
                              number_account:
                                e !== null ? e.number_account : "",
                            })
                          }
                          options={this.state.bankOption}
                          value={this.state.bankOption.filter(
                            (obj) => obj.value === this.state.id_rek
                          )}
                          isDisabled={this.state.showLoadingBank}
                          isLoading={this.state.showLoadingBank}
                          placeholder="Please select bank"
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_rek}
                          required
                          onChange={(e) =>
                            this.setState({ id_rek: e.target.value })
                          }
                        /> */}
                      </div>
                      {/* {this.state.id_rek === "" ? null : (
                        <div style={{ marginBottom: "10px" }}>
                          <>
                            <p className="mb-0">
                              <b>{this.state.name_bank}</b>
                              {" - "}
                              {this.state.number_account} a/n{" "}
                              <b>{this.state.an}</b>
                            </p>
                          </>
                        </div>
                      )} */}
                    </div>
                    {/* <h5 style={{ marginTop: "20px" }}>Shipping Method</h5>
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        height: 50,
                        minHeight: 50,
                      }),
                    }}
                    onChange={(e) =>
                      this.setState({
                        shippingMethod: e !== null ? e.value : "",
                      })
                    }
                    options={this.state.shippingMethodOption}
                    value={this.state.shippingMethodOption.filter(
                      (obj) => obj.value === this.state.shippingMethod
                    )}
                    isDisabled={this.state.showLoadingshippingMethod}
                    isLoading={this.state.showLoadingshippingMethod}
                    placeholder="Please select delivery service"
                  />
                  <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{
                      opacity: 0,
                      height: 0,
                      position: "absolute",
                    }}
                    value={this.state.shippingMethod}
                    required
                    onChange={(e) =>
                      this.setState({ shippingMethod: e.target.value })
                    }
                  /> */}
                  </div>
                  <div className="col-lg-5">
                    <div className="checkout__order">
                      <h5>Your order</h5>
                      <div className="checkout__order__product">
                        <table
                          style={{ border: "none", width: "100%" }}
                          border={0}
                          // cellPadding={5}
                        >
                          <thead style={{ paddingBottom: "20px" }}>
                            <tr>
                              <th style={{ width: "50%" }}>Product</th>
                              <th
                                className="text-right"
                                style={{ width: "10%" }}
                              >
                                Qty
                              </th>
                              <th
                                className="text-right"
                                style={{ width: "40%" }}
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listCart.length
                              ? this.state.listCart.map((cart, idx) => (
                                  <tr key={idx}>
                                    <td style={{ padding: 0 }}>
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontSize: "13px",
                                        }}
                                      >
                                        <b>{cart.produkname}</b>
                                      </p>
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontSize: "10px",
                                        }}
                                      >{`${cart.options ? cart.options : ""}${
                                        cart.options_second
                                          ? ", " + cart.options_second
                                          : ""
                                      }`}</p>
                                    </td>
                                    <td
                                      style={{ padding: 0 }}
                                      className="text-right"
                                    >
                                      {cart.qty}
                                    </td>
                                    <td
                                      style={{ padding: 0 }}
                                      className="text-right"
                                    >
                                      <span>
                                        Rp.{" "}
                                        {/* {currencyFormatIndo(
                                        this.getTotal(cart.last_price, cart.qty)
                                      )} */}
                                        {cart.last_price}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      <Spin
                        spinning={
                          this.state.showLoadingCekOngkir ||
                          this.state.showLoadingAddress
                        }
                      >
                        <div className="checkout__order__total">
                          <ul>
                            <li>
                              Subtotal{" "}
                              <span>
                                Rp.{" "}
                                {currencyFormatIndo(this.state.totalPriceCart)}
                              </span>
                            </li>
                            <li>
                              Shipping Fee{" "}
                              {/* Shipping Fee {`${this.state.serviceShipping !== "" ? "(" +this.state.serviceShipping + ")" :  ""}`}{" "} */}
                              <span>
                                Rp.{" "}
                                {currencyFormatIndo(
                                  parseInt(this.state.total_shipping) +
                                    parseInt(this.state.additional)
                                )}
                              </span>
                            </li>
                            <li>
                              Total{" "}
                              <span>
                                Rp.{" "}
                                {currencyFormatIndo(
                                  this.getTotalOrder(
                                    this.state.totalPriceCart,
                                    this.state.total_shipping,
                                    this.state.additional
                                  )
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Spin>
                      <button
                        type="submit"
                        className="site-btn"
                        disabled={
                          !this.state.listAddress.length ||
                          this.state.showLoading
                        }
                        style={{
                          cursor:
                            !this.state.listAddress.length ||
                            this.state.showLoading
                              ? "not-allowed"
                              : "pointer",
                          backgroundColor:
                            !this.state.listAddress.length ||
                            this.state.showLoading
                              ? "#fa6969"
                              : "#ca1515",
                        }}
                      >
                        {this.state.showLoading ? "Processing" : "Place Order"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Spin>

        <Modal
          title={null}
          visible={this.state.isModalVisible}
          // onOk={false}
          onCancel={() => this.toggleModal("isModalVisible")}
          footer={null}
          width={800}
          style={{ top: 60 }}
          zIndex={999}
        >
          <form onSubmit={this.handleSubmitAddress} className="checkout__form">
            <div className="row">
              <div className="col-lg-12">
                <h5>New Address</h5>
                <div className="row">
                  <div className="col-md-12">
                    <div className="checkout__form__input">
                      <p>
                        Receiver <span>*</span>
                      </p>
                      <input
                        type="text"
                        value={this.state.receiver}
                        onChange={(e) =>
                          this.setState({ receiver: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="checkout__form__input">
                      <p>
                        Address <span>*</span>
                      </p>
                      <textarea
                        style={{
                          // height: '20px',
                          width: "100%",
                          border: "1px solid #e1e1e1",
                          borderRadius: "2px",
                          marginBottom: "25px",
                          fontSize: "14px",
                          paddingLeft: "20px",
                          paddingTop: "15px",
                          color: "#666666",
                        }}
                        value={this.state.address}
                        onChange={(e) =>
                          this.setState({ address: e.target.value })
                        }
                        required
                        rows={3}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      style={{
                        marginBottom: "25px",
                      }}
                    >
                      <p
                        style={{
                          color: "#444444",
                          fontWeight: 500,
                          marginBottom: "10px",
                        }}
                      >
                        Province <span className="text-danger">*</span>
                      </p>
                      <>
                        <Select
                          isClearable={true}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 50,
                              minHeight: 50,
                            }),
                          }}
                          onChange={(e) => this.handleChangeProvince(e)}
                          // onChange={(e) =>
                          //   this.setState({
                          //     id_province: e !== null ? e.value : "",
                          //   })
                          // }
                          options={this.state.provinceOption}
                          value={this.state.provinceOption.filter(
                            (obj) => obj.value === this.state.id_province
                          )}
                          isDisabled={this.state.showLoadingProvince}
                          isLoading={this.state.showLoadingProvince}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_province}
                          required
                          onChange={(e) =>
                            this.setState({ id_province: e.target.value })
                          }
                        />
                      </>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      style={{
                        marginBottom: "25px",
                      }}
                    >
                      <p
                        style={{
                          color: "#444444",
                          fontWeight: 500,
                          marginBottom: "10px",
                        }}
                      >
                        City <span className="text-danger">*</span>
                      </p>
                      <>
                        <Select
                          isClearable={true}
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 50,
                              minHeight: 50,
                              width: "100%",
                            }),
                          }}
                          onChange={(e) => this.handleChangeCity(e)}
                          // onChange={(e) =>
                          //   this.setState({
                          //     id_kabupaten: e !== null ? e.value : "",
                          //   })
                          // }
                          options={this.state.cityOption}
                          value={this.state.cityOption.filter(
                            (obj) => obj.value === this.state.id_kabupaten
                          )}
                          isDisabled={this.state.showLoadingCity}
                          isLoading={this.state.showLoadingCity}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_kabupaten}
                          required
                          onChange={(e) =>
                            this.setState({ id_kabupaten: e.target.value })
                          }
                        />
                      </>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="checkout__form__input">
                      <p>
                        Zip Code <span>*</span>
                      </p>
                      {/* <input
                          type="text"
                          value={this.state.zip_code}
                          onChange={(e) =>
                            this.setState({ zip_code: e.target.value })
                          }
                          required
                        /> */}
                      <NumberFormat
                        // style={{ textAlign: "right" }}
                        className="form-control"
                        value={this.state.zip_code}
                        prefix={""}
                        onValueChange={(values) =>
                          this.setState({ zip_code: values.floatValue })
                        }
                        allowEmptyFormatting={false}
                        // format="#### #### #### ####"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="checkout__form__input">
                      <p>
                        Phone <span>*</span>
                      </p>
                      {/* <input
                          type="text"
                          value={this.state.contact}
                          onChange={(e) =>
                            this.setState({ contact: e.target.value })
                          }
                          required
                        /> */}
                      <NumberFormat
                        // style={{ textAlign: "right" }}
                        className="form-control"
                        value={this.state.phone}
                        prefix={""}
                        onValueChange={(values) =>
                          this.setState({ phone: values.floatValue })
                        }
                        format="+62 (###) #### #### ####"
                        // mask={"_"}
                        allowEmptyFormatting={false}
                        required
                        // placeholder="+62 "
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="checkout__form__input">
                      <p>
                        Default <span>*</span>
                      </p>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        checked={this.state.default === "N" ? false : true}
                        onChange={(checked) =>
                          this.setState({
                            default: checked === true ? "Y" : "N",
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="checkout__form__input">
                      <button
                        type="submit"
                        // style={{ width: "100%" }}
                        className="submit-btn"
                        disabled={this.state.isDisabled}
                      >
                        {this.state.isDisabled ? (
                          <div>
                            <i className="fa fa-refresh fa-spin"></i> Executing
                            your request
                          </div>
                        ) : (
                          "Save Changes"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        <Modal
          title={`Address`}
          centered
          visible={this.state.showAddressModal}
          // onOk={() => setVisible(false)}
          onCancel={() =>
            this.setState({
              showAddressModal: false,
            })
          }
          footer={null}
          width={1200}
          zIndex={10}
        >
          <Spin spinning={this.state.showLoadingAddress}>
            <div>
              <a
                className="ml-4"
                href="#"
                onClick={() => this.openModalAddress()}
              >
                + Add New
              </a>
            </div>
            <div className="table-responsive">
              <table className="styled-table-variant">
                <thead>
                  <tr>
                    <th>Address</th>
                    <th>City</th>
                    <th>Province</th>
                    {/* <th>District</th>
                    <th>Village</th> */}
                    <th>Zip Code</th>
                    <th>Receiver</th>
                    <th>Contact</th>
                    <th>Default Address</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.listAddress.length ? (
                    this.state.listAddress.map((item, x) => (
                      <tr key={x}>
                        <td>
                          <a
                            href="#"
                            onClick={() => this.editDataAddress(item)}
                          >
                            {item.address}
                          </a>
                        </td>
                        <td>{item.city_name}</td>
                        <td>{item.province}</td>
                        {/* <td>{item.kecamatan}</td>
                        <td>{item.kelurahan}</td> */}
                        <td>{item.zip_code}</td>
                        <td>{item.receiver}</td>
                        <td>{item.phone}</td>
                        <td className="text-center">
                          {item.default === "Y" ? (
                            <CheckCircleTwoTone
                              twoToneColor="#52c41a"
                              style={{ fontSize: "24px" }}
                            />
                          ) : null}
                        </td>
                        <td className="text-center">
                          <a href="#" onClick={() => this.changeDefault(item)}>
                            Change to shipping
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Spin>
        </Modal>

       

        <Modal
          title={null}
          visible={this.state.modalDialogConfirmation}
          // onOk={false}
          onCancel={() => this.toggleModal("modalDialogConfirmation")}
          footer={null}
          width={800}
          style={{ top: 60 }}
        >
          <form onSubmit={this.handleExecuteOrder} className="checkout__form">
            <div className="row">
              <div className="col-lg-12">
                <h5>Confirmation</h5>
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      Sorry, there is an update regarding the total payment,
                      please check again, if you want to continue the
                      transaction click the continue order button
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="checkout__order">
                      <h5>Your order</h5>
                      <div className="checkout__order__product">
                        <table
                          style={{ border: "none", width: "100%" }}
                          border={0}
                          // cellPadding={5}
                        >
                          <thead style={{ paddingBottom: "20px" }}>
                            <tr>
                              <th style={{ width: "50%" }}>Product</th>
                              <th
                                className="text-right"
                                style={{ width: "10%" }}
                              >
                                Qty
                              </th>
                              <th
                                className="text-right"
                                style={{ width: "40%" }}
                              >
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.listCart.length
                              ? this.state.listCart.map((cart, idx) => (
                                  <tr key={idx}>
                                    <td style={{ padding: 0 }}>
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontSize: "13px",
                                        }}
                                      >
                                        <b>{cart.produkname}</b>
                                      </p>
                                      <p
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          fontSize: "10px",
                                        }}
                                      >{`${cart.options ? cart.options : ""}${
                                        cart.options_second
                                          ? ", " + cart.options_second
                                          : ""
                                      }`}</p>
                                    </td>
                                    <td
                                      style={{ padding: 0 }}
                                      className="text-right"
                                    >
                                      {cart.qty}
                                    </td>
                                    <td
                                      style={{ padding: 0 }}
                                      className="text-right"
                                    >
                                      <span>
                                        Rp.{" "}
                                        {/* {currencyFormatIndo(
                                        this.getTotal(cart.last_price, cart.qty)
                                      )} */}
                                        {cart.last_price}
                                      </span>
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      <Spin
                        spinning={
                          this.state.showLoadingCekOngkir ||
                          this.state.showLoadingAddress
                        }
                      >
                        <div className="checkout__order__total">
                          <ul>
                            <li>
                              Subtotal{" "}
                              <span>
                                Rp.{" "}
                                {currencyFormatIndo(this.state.totalPriceCart)}
                              </span>
                            </li>
                            <li>
                              Shipping Fee{" "}
                              {/* Shipping Fee {`${this.state.serviceShipping !== "" ? "(" +this.state.serviceShipping + ")" :  ""}`}{" "} */}
                              <span>
                                Rp.{" "}
                                {currencyFormatIndo(
                                  parseInt(this.state.total_shipping) +
                                    parseInt(this.state.Newadditional)
                                )}
                              </span>
                            </li>
                            <li>
                              Total{" "}
                              <span>
                                Rp.{" "}
                                {currencyFormatIndo(
                                  this.getTotalOrder(
                                    this.state.totalPriceCart,
                                    this.state.total_shipping,
                                    this.state.Newadditional
                                  )
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Spin>
                    </div>
                  </div>
                  <div className="col-md-12 mt-5">
                    <div className="checkout__form__input">
                      <button
                        type="submit"
                        // style={{ width: "100%" }}
                        className="submit-btn mr-3"
                        disabled={this.state.isDisabled}
                      >
                        {this.state.isDisabled ? (
                          <div>
                            <i className="fa fa-refresh fa-spin"></i> Executing
                            your request
                          </div>
                        ) : (
                          "Continue Order"
                        )}
                      </button>
                      <button
                        type="button"
                        // style={{ width: "100%" }}
                        className="submit-btn"
                        disabled={this.state.isDisabled}
                        onClick={() =>
                          this.toggleModal("modalDialogConfirmation")
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal>

        <ToastContainer theme="dark" />
      </>
    );
  }
}
